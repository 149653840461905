import { createAction } from '@reduxjs/toolkit';

import { EnvelopeReviewRule, SectionType } from 'reducers/envelopeReview';
import type { CommunicationEnvelope, EntityTag, SavedSearch, Tag, UUID } from 'types';

export type AddRemoveTagPayload = {
  tag: Partial<Tag>;
};

export const changeTags = createAction<EntityTag[]>('ENVELOPE_REVIEW/CHANGE_TAGS');

export const addTagToReview = createAction<AddRemoveTagPayload>('ENVELOPE_REVIEW/ADD_TAG');

export const removeTagFromReview = createAction<AddRemoveTagPayload>('ENVELOPE_REVIEW/REMOVE_TAG');

export const changeCodingReviewStatus = createAction<boolean>(
  'ENVELOPE_REVIEW/SET_CODING_REVIEW_STATUS'
);

export type ChangeStatusPayload = {
  status: string;
};

export const changeStatusInReview = createAction<ChangeStatusPayload>(
  'ENVELOPE_REVIEW/CHANGE_STATUS'
);

export const changeDeclaredStatus = createAction<ChangeStatusPayload>(
  'ENVELOPE_REVIEW/CHANGE_DECLARED_STATUS'
);

export type ChangeEnvelopePayload = {
  envelope: CommunicationEnvelope;
};

export const changeEnvelopeInReview = createAction<ChangeEnvelopePayload>(
  'ENVELOPE_REVIEW/CHANGE_ENVELOPE'
);

export type ChangeEnvelopeReviewerPayload = {
  reviewer: string;
};

export const changeEnvelopeReviewer = createAction<ChangeEnvelopeReviewerPayload>(
  'ENVELOPE_REVIEW/CHANGE_ENVELOPE_Reviewer'
);

export const changeBulkReviewStatus = createAction<boolean>(
  'ENVELOPE_REVIEW/CHANGE_BULK_REVIEW_STATUS'
);

export const setSelectedThread = createAction<UUID[]>('ENVELOPE_REVIEW/SET_SELECTED_THREADS');

export const selectRuleEnvelopeReview = createAction<EnvelopeReviewRule | null>(
  'ENVELOPE_REVIEW/SELECT_RULE'
);

export const selectCampaignEnvelopeReview = createAction<string | null>(
  'ENVELOPE_REVIEW/SELECT_CAMPAIGN'
);

export type ModalAfterReview = {
  modal: 'withSkips' | 'withoutSkips';
  reviewSet: SavedSearch | undefined;
} | null;

export const setModalAfterReview = createAction<ModalAfterReview>(
  'ENVELOPE_REVIEW/SET_MODAL_AFTER_REVIEW'
);

export const toogleBackButtonHit = createAction<boolean>('ENVELOPE_REVIEW/BACK_BUTTON_HIT');

export const selectSection = createAction<SectionType>('ENVELOPE_REVIEW/SELECT_SECTION');

export const setReviewOnSkipView = createAction<boolean>('ENVELOPE_REVIEW/REVIEW_ON_SKIP_VIEW');

export const setCommentError = createAction<boolean>('ENVELOPE_REVIEW/SET_COMMENT_ERROR');
