/* eslint-disable camelcase */

import { addAndRemoveValuesToTree, removeFieldFromTree, showSuccessAlert } from 'actions';
import Modal from 'components/Modal';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getNavParamsFromTree } from 'selectors/nav';
import { useSelector } from 'store';

type ComponentProps = {
  isModalOpen: boolean;
  toggleModalOpen: () => void;
};

const AdvancedSearchModal: React.FC<ComponentProps> = ({ isModalOpen, toggleModalOpen }) => {
  const dispatch = useDispatch();

  const { broad_search, subject, attachment_text, uuid, body } = useSelector(
    getNavParamsFromTree
  ) as {
    broad_search: string;
    subject: string;
    attachment_text: string;
    uuid: string;
    body: string;
  };

  const { register, getValues, handleSubmit, reset, watch } = useForm<{
    search: string;
    onlySubject: boolean;
    onlyAttachment: boolean;
    onlyUUID: boolean;
    onlyBody: boolean;
  }>({
    defaultValues: {
      search: broad_search || subject || '',
      onlySubject: subject?.length > 0,
      onlyAttachment: attachment_text?.length > 0,
      onlyUUID: uuid?.length > 0,
      onlyBody: body?.length > 0,
    },
  });

  const {
    onlySubject: selectedOnlySubject,
    onlyAttachment: selectedOnlyAttachment,
    onlyUUID: selectedOnlyUUID,
    onlyBody: selectedOnlyBody,
  } = watch();

  useEffect(() => {
    if (!selectedOnlySubject) {
      dispatch(removeFieldFromTree({ field: 'subject' }));
    }
    if (!selectedOnlyAttachment) {
      dispatch(removeFieldFromTree({ field: 'attachment_text' }));
    }
    if (!selectedOnlyUUID) {
      dispatch(removeFieldFromTree({ field: 'uuid' }));
    }
    if (!selectedOnlyBody) {
      dispatch(removeFieldFromTree({ field: 'body' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOnlySubject, selectedOnlyAttachment, selectedOnlyUUID, selectedOnlyBody]);

  const onSubmit = (): void => {
    const { search, onlySubject, onlyAttachment, onlyUUID, onlyBody } = getValues();

    if (search.trim()) {
      const valueToAdd: { value: string; label: string; isSingleValue: boolean; field: string } = {
        field: '',
        value: search.trim(),
        label: search.trim(),
        isSingleValue: true,
      };

      let fieldsToRemove = ['broad_search'];

      if (onlyUUID) {
        valueToAdd.field = 'uuid';
        fieldsToRemove.push('subject', 'attachment_text', 'body');
      } else if (onlyBody) {
        valueToAdd.field = 'body';
        fieldsToRemove.push('subject', 'attachment_text', 'uuid');
      } else if (onlySubject) {
        valueToAdd.field = 'subject';
        fieldsToRemove.push('attachment_text', 'uuid', 'body');
      } else if (onlyAttachment) {
        valueToAdd.field = 'attachment_text';
        fieldsToRemove.push('subject', 'uuid', 'body');
      } else {
        valueToAdd.field = 'broad_search';
        fieldsToRemove.push('subject', 'attachment_text', 'uuid', 'body');
        fieldsToRemove = fieldsToRemove.filter((field) => field !== 'broad_search');
      }

      dispatch(
        addAndRemoveValuesToTree({
          valueToAdd,
          fieldsToRemove,
        })
      );

      toggleModalOpen();
      reset();
    }
  };

  const handleSearchButton = (): void => {
    handleSubmit(onSubmit)();
  };

  const handleOpenSyntaxResource = (): void => {
    window.open(
      'https://lit-public-static-content.s3.us-east-2.amazonaws.com/LitLingo+Search+Syntax+(1).pdf',
      '_blank'
    );
  };

  const handleCopy = async (): Promise<void> => {
    const regex = /(?<=")([A-Za-z0-9]+)(?=")/g;
    const text = getValues()
      .search.match(regex)
      ?.reduce((acc, cur, idx) => {
        if (idx === 0) return cur;
        return `${acc}\n${cur}`;
      }, '');

    if (text) {
      await navigator.clipboard.writeText(text);
      dispatch(showSuccessAlert('Terms copied to your clipboard'));
    }
  };

  const renderAdvancedSearch = (
    <div className="pt-2 pb-4 flex flex-col gap-6">
      <div className="flex flex-col items-start gap-1 text-body">
        <span>
          This advanced query allows you to create more complex searches through your data using
          elastic search.
        </span>
        <button
          type="button"
          className="italic text-litlingo-primary underline focus:outline-none"
          onClick={handleOpenSyntaxResource}
        >
          What types of queries are there?
        </button>
      </div>

      <form>
        <textarea
          id="search"
          ref={register()}
          name="search"
          rows={12}
          placeholder="Type your advanced query here."
          className="form-input w-full p-2 text-body rounded-md resize-none placeholder-italic placeholder-litlingo-gray-6 border-litlingo-gray-2"
        />
        <div className="flex flex-row gap-2">
          <label className="flex flex-row items-center gap-1" htmlFor="subject-checkbox">
            <input
              type="checkbox"
              className="form-checkbox litlingo-checkbox"
              name="onlySubject"
              id="subject-checkbox"
              data-testid="subject-checkbox"
              ref={register()}
              disabled={selectedOnlyUUID || selectedOnlyAttachment || selectedOnlyBody}
            />
            <span className="text-small select-none break-all">Only subject</span>
          </label>
          <label className="flex flex-row items-center gap-1" htmlFor="attachment-checkbox">
            <input
              type="checkbox"
              className="form-checkbox litlingo-checkbox"
              name="onlyAttachment"
              id="attachment-checkbox"
              data-testid="attachment-checkbox"
              ref={register()}
              disabled={selectedOnlyUUID || selectedOnlySubject || selectedOnlyBody}
            />
            <span className="text-small select-none break-all">Only attachment</span>
          </label>
          <label className="flex flex-row items-center gap-1" htmlFor="uuid-checkbox">
            <input
              type="checkbox"
              className="form-checkbox litlingo-checkbox"
              name="onlyUUID"
              id="uuid-checkbox"
              data-testid="uuid-checkbox"
              ref={register()}
              disabled={selectedOnlySubject || selectedOnlyAttachment || selectedOnlyBody}
            />
            <span className="text-small select-none break-all">Only UUID</span>
          </label>
          <label className="flex flex-row items-center gap-1" htmlFor="body-checkbox">
            <input
              type="checkbox"
              className="form-checkbox litlingo-checkbox"
              name="onlyBody"
              id="body-checkbox"
              data-testid="body-checkbox"
              ref={register()}
              disabled={selectedOnlySubject || selectedOnlyAttachment || selectedOnlyUUID}
            />
            <span className="text-small select-none break-all">Only Body</span>
          </label>
          <button type="button" onClick={handleCopy} className="text-small">
            <span className="text-litlingo-primary-120 underline focus:outline-none">
              Copy for Identifier Terms
            </span>
          </button>
        </div>
      </form>
    </div>
  );

  return (
    <>
      {isModalOpen && (
        <Modal
          body={renderAdvancedSearch}
          title="Advanced Query"
          okButton
          okButtonText="Search"
          okButtonOnClick={handleSearchButton}
          toggleShowModal={toggleModalOpen}
          style={{ width: '900px' }}
        />
      )}
    </>
  );
};

export default AdvancedSearchModal;
