import { createAction } from '@reduxjs/toolkit';
import type {
  API,
  AnnotatorTypes,
  Customer,
  ErrorObject,
  Identifier,
  IdentifierRevision,
  LanguageMatcher,
  LanguageMatcherType,
  LanguageMatchersTypes,
  MLanguageMatcher,
  NormalizedIdentifier,
  RelationshipType,
  UUID,
} from 'types';

type FetchIdentifiersForFilterPayload = {
  searchValue: string;
  limit?: number;
  offset?: number;
};
export const fetchIdentifiersForFilter = createAction<FetchIdentifiersForFilterPayload>(
  'IDENTIFIERS_FILTER/FETCH'
);

export const fetchAllIdentifiers = createAction<{
  name?: string;
  limit?: string;
  searchValue?: string;
  group?: string;
}>('IDENTIFIERS/FETCH');
export const fetchAllIdentifiersRequest = createAction('IDENTIFIERS/FETCH_REQUEST');
export const fetchAllIdentifiersSuccess = createAction<API.WrappedAPIResponse<Identifier>>(
  'IDENTIFIERS/FETCH_SUCCESS'
);
export const fetchAllIdentifiersSuccessAppend = createAction<API.WrappedAPIResponse<Identifier>>(
  'IDENTIFIERS/FETCH_SUCCESS_APPEND'
);
export const fetchAllIdentifiersFailure = createAction<ErrorObject>('IDENTIFIERS/FETCH_FAILURE');

type FetchIdentifiersByIds = { identifierIds: string[] };

export const fetchIdentifiersByIds = createAction<FetchIdentifiersByIds>(
  'IDENTIFIERS/FETCH_BY_IDS'
);
export const fetchIdentifiersByIdsRequest = createAction('IDENTIFIERS/FETCH_BY_IDS_REQUEST');
export const fetchIdentifiersByIdsSuccess = createAction<API.WrappedAPIResponse<Identifier>>(
  'IDENTIFIERS/FETCH_BY_IDS_SUCCESS'
);
export const fetchIdentifiersByIdsFailure = createAction<ErrorObject>(
  'IDENTIFIERS/FETCH_BY_IDS_FAILURE'
);

export const fetchSingleIdentifier = createAction<{ identifierId: UUID }>(
  'IDENTIFIERS/FETCH_SINGLE'
);
export const fetchSingleIdentifierRequest = createAction('IDENTIFIERS/FETCH_SINGLE_REQUEST');
export const fetchSingleIdentifierSuccess = createAction<{
  identifier: Identifier;
  identifierRevision: IdentifierRevision;
}>('IDENTIFIERS/FETCH_SINGLE_SUCCESS');
export const fetchSingleIdentifierFailure = createAction<ErrorObject>(
  'IDENTIFIERS/FETCH_SINGLE_FAILURE'
);

export const fetchIdentifierRevision = createAction<{
  identifierRevisionId: UUID;
}>('IDENTIFIERS/FETCH_REVISION');

export const fetchIdentifierRevisionRequest = createAction('IDENTIFIERS/FETCH_REVISION_REQUEST');
export const fetchIdentifierRevisionSuccess = createAction<{
  identifier: Identifier;
  identifierRevision: IdentifierRevision;
}>('IDENTIFIERS/FETCH_REVISION_SUCCESS');
export const fetchIdentifierRevisionFailure = createAction<ErrorObject>(
  'IDENTIFIERS/FETCH_REVISION_FAILURE'
);

type DeleteIdentifierPayload = {
  identifierId: UUID;
  redirect?: boolean;
  fetchFn?: () => void;
};
export const deleteIdentifier = createAction<DeleteIdentifierPayload>('IDENTIFIERS/DELETE');
export const deleteIdentifierRequest = createAction('IDENTIFIERS/DELETE_REQUEST');
export const deleteIdentifierSuccess = createAction<string>('IDENTIFIERS/DELETE_SUCCESS');
export const deleteIdentifierFailure = createAction<ErrorObject>('IDENTIFIERS/DELETE_FAILURE');

type BulkDeleteIdentifierPayload = {
  identifierIds: UUID[];
  fetchFn?: () => void;
};

export const bulkDeleteIdentifier =
  createAction<BulkDeleteIdentifierPayload>('IDENTIFIERS/BULK_DELETE');
export const bulkDeleteIdentifierRequest = createAction('IDENTIFIERS/BULK_DELETE_REQUEST');
export const bulkDeleteIdentifierSuccess = createAction<Identifier[]>(
  'IDENTIFIERS/BULK_DELETE_SUCCESS'
);
export const bulkDeleteIdentifierFailure = createAction<ErrorObject>(
  'IDENTIFIERS/BULK_DELETE_FAILURE'
);

export const saveIdentifierRequest = createAction('IDENTIFIERS/SAVE_REQUEST');
export const saveIdentifierSuccess = createAction<Identifier>('IDENTIFIERS/SAVE_SUCCESS');
export const saveIdentifierFailure = createAction<ErrorObject>('IDENTIFIERS/SAVE_FAILURE');
export const saveIdentifierFulfill = createAction('IDENTIFIERS/SAVE_FULFILL');
export const saveIdentifierLocalFailure = createAction<UpsertIdentifierPayload>(
  'IDENTIFIERS/SAVE_LOCAL_FAILURE'
);

export const fetchIdentifierTypes = createAction('IDENTIFIERS/FETCH_TYPES');
export const fetchIdentifierTypesRequest = createAction('IDENTIFIERS/FETCH_TYPES_REQUEST');
export const fetchIdentifierTypesSuccess = createAction<AnnotatorTypes>(
  'IDENTIFIERS/FETCH_TYPES_SUCCESS'
);
export const fetchIdentifierTypesFailure = createAction<ErrorObject>(
  'IDENTIFIERS/FETCH_TYPES_FAILURE'
);

export const fetchLanguageMatcherTypes = createAction('LANGUAGE_MATCHERS_IDENTIFIER/FETCH');
export const fetchLanguageMatcherTypesRequest = createAction(
  'LANGUAGE_MATCHERS_IDENTIFIER/FETCH_REQUEST'
);
export const fetchLanguageMatcherTypesSuccess = createAction<LanguageMatchersTypes>(
  'LANGUAGE_MATCHERS_IDENTIFIER/FETCH_SUCCESS'
);
export const fetchLanguageMatcherTypesFailure = createAction<ErrorObject>(
  'LANGUAGE_MATCHERS_IDENTIFIER/FETCH_FAILURE'
);

export const fetchRelationshipTypes = createAction('RELATIONSHP_TYPES_IDENTIFIER/FETCH');
export const fetchRelationshipTypesRequest = createAction(
  'RELATIONSHIP_TYPES_IDENTIFIER/FETCH_REQUEST'
);
export const fetchRelationshipTypesSuccess = createAction<RelationshipType[]>(
  'RELATIONSHIP_TYPES_IDENTIFIER/FETCH_SUCCESS'
);
export const fetchRelationshipTypesFailure = createAction<ErrorObject>(
  'RELATIONSHIP_TYPES_IDENTIFIER/FETCH_FAILURE'
);

export const cloneIdentifier = createAction<{ identifierId: UUID }>('IDENTIFIER/CLONE');
export const cloneIdentifierRequest = createAction('IDENTIFIER/CLONE_REQUEST');
export const cloneIdentifierFailure = createAction<ErrorObject>('IDENTIFIER/CLONE_FAILURE');
export const cloneIdentifierSuccess = createAction<Identifier>('IDENTIFIER/CLONE_SUCCESS');

export type AddNewLanguageMatcherPayload = {
  matcherBody: Pick<MLanguageMatcher, 'name' | 'saved'>;
  identifierId: UUID;
  languageMatcherId: UUID;
};
export const addNewLanguageMatcher = createAction<AddNewLanguageMatcherPayload>(
  'IDENTIFIER/ADD_LANGUAGE_MATCHER'
);

export type ReceiveNewKeywordPayload = {
  languageMatcherId: UUID;
  matcherBody?: LanguageMatcher;
  parentType: LanguageMatcherType;
  data: {
    key: keyof MLanguageMatcher;
    value?: string | number | boolean | (string | number | boolean | undefined)[];
  };
};
export const receiveNewKeyword = createAction<ReceiveNewKeywordPayload>(
  'IDENTIFIER/RECEIVE_NEW_KEYWORD'
);

export type ChangeLanguageMatcherTypePayload = {
  languageMatcherId: UUID;
  type: string;
};
export const changeLanguageMatcherType = createAction<ChangeLanguageMatcherTypePayload>(
  'IDENTIFIER/CHANGE_LANGUAGE_MATCHER_TYPE'
);

export const setActiveLanguageMatcherId = createAction<string>(
  'IDENTIFIER/SET_ACTIVE_LANGUAGE_MATCHER'
);

export type DeleteLanguageMatcherPayload = {
  identifierId: UUID;
  languageMatcherId: UUID;
};
export const deleteLanguageMatcher = createAction<DeleteLanguageMatcherPayload>(
  'IDENTIFIER/DELETE_LANGUAGE_MATCHER'
);

export type EditLanguageMatcherPayload = {
  languageMatcherId: UUID;
  data: Partial<LanguageMatcher>;
};

export const editLanguageMatcher = createAction<EditLanguageMatcherPayload>(
  'IDENTIFIER/EDIT_LANGUAGE_MATCHER'
);

export type RemoveKeywordPayload = {
  languageMatcherId: UUID;
  keyword: string | boolean | undefined;
  // FIXME: use the correct type for this
  partOfSpeech: keyof LanguageMatcher;
};
export const removeKeyword = createAction<RemoveKeywordPayload>('IDENTIFIER/REMOVE_KEYWORD');

export type EditIdentifierPayload = {
  id: UUID;
  data: Pick<NormalizedIdentifier, 'name'> | Pick<NormalizedIdentifier, 'always_save'>;
};
export const editIdentifier = createAction<EditIdentifierPayload>('IDENTIFIER/EDIT');

export type CreateIdentifierPayload = {
  name?: string;
  description?: string | null;
  // eslint-disable-next-line camelcase
  options_config: AnnotatorTypes[string]['options'];
  // eslint-disable-next-line camelcase
  parent_uuid?: UUID;
  identifier_uuid?: UUID;
  language_matchers?: LanguageMatcher[];
  always_save?: boolean;
  redirect?: boolean;
  kind?: string;
};

export const createIdentifier = createAction<CreateIdentifierPayload>('IDENTIFIER/CREATE');

export type UpsertIdentifierPayload = {
  identifierId?: UUID;
  // eslint-disable-next-line camelcase
  options_config: AnnotatorTypes[string]['options'];
  showSuccess?: boolean;
  name?: string;
  description?: string | null;
  // eslint-disable-next-line camelcase
  always_save?: boolean;
  redirect?: boolean;
  type?: string;
  ruleId?: string;
  campaignId?: string;
};

export const upsertIdentifier = createAction<UpsertIdentifierPayload>('IDENTIFIER/UPSERT');

export const upsertIdentifierDebounced = createAction<UpsertIdentifierPayload>(
  'IDENTIFIER/UPSERT_DEBOUNCED'
);

export type FetchIdentifierChangelogType = {
  identifierId: UUID;
  auditlogUuid: UUID;
  version: string;
};

export const fetchIdentifierChangelog = createAction<FetchIdentifierChangelogType>(
  'IDENTIFIER/FETCH_CHANGELOG'
);

export const setHasChanges = createAction<boolean>('IDENTIFIER/SET_HAS_CHANGES');

export type ReplaceIdentifierType = {
  source: UUID;
  targets: UUID[];
};

export const replaceIdentifier = createAction<ReplaceIdentifierType>(
  'IDENTIFIER/REPLACE_IDENTIFIER'
);
export const replaceIdentifierRequest = createAction('IDENTIFIER/REPLACE_REQUEST');
export const replaceIdentifierFailure = createAction<ErrorObject>('IDENTIFIER/REPLACE_FAILURE');
export const replaceIdentifierSuccess = createAction('IDENTIFIER/REPLACE_SUCCESS');

type GenerateTermsType = {
  includedTerms: string[];
};

export const generateTerms = createAction<GenerateTermsType>('IDENTIFIER/GENERATE_TERMS');
export const generateTermsRequest = createAction('IDENTIFIER/GENERATE_TERMS_REQUEST');
export const generateTermsFailure = createAction<ErrorObject>('IDENTIFIER/GENERATE_TERMS_FAILURE');
export const generateTermsSuccess = createAction<string[]>('IDENTIFIER/GENERATE_TERMS_SUCCESS');

export const setDropPosition = createAction<number>('IDENTIFIER/SET_DROP_POSITION');

export const discardIdentifierChanges = createAction('IDENTIFIER/DISCARD_CHANGES');

export const selectCustomerIdentifier = createAction<string>('IDENTIFIER/SELECT_CUSTOMER');

export type ChangeScopePayload = {
  matcherId: UUID;
  customerId: UUID;
  act: 'add' | 'remove';
};

export const changeScopeIdentifier = createAction<ChangeScopePayload>('IDENTIFIER/CHANGE_SCOPE');

export const fetchIdentifierCustomers = createAction<string>('IDENTIFIER/FETCH_CUSTOMERS');
export const fetchIdentifierCustomersRequest = createAction('IDENTIFIER/FETCH_CUSTOMERS_REQUEST');
export const fetchIdentifierCustomersSuccess = createAction<Customer[]>(
  'IDENTIFIER/FETCH_CUSTOMERS_SUCCESS'
);
export const fetchIdentifierCustomersFailure = createAction<ErrorObject>(
  'IDENTIFIER/FETCH_CUSTOMERS_FAILURE'
);
export const fetchIdentifierCustomersFulfill = createAction('IDENTIFIER/FETCH_CUSTOMERS_FULFILL');

export const setCurrentTestIdentifierRevisionId = createAction<string>(
  'IDENTIFIER/TEST_IDENTIFIER_REVISION_ID'
);

export const clearIdentifier = createAction('IDENTIFIER/CLEAR');

export const setShowUtilization = createAction<boolean>('IDENTIFIER/SHOW_UTILIZATION');

export const setShowEditIdentifier = createAction<boolean>('IDENTIFIER/SHOW_EDIT_IDENTIFIER');
