/* eslint-disable camelcase */
/* eslint-disable max-lines */

import {
  addCommentEnvelope,
  fetchAllEnvelopes,
  reviewAndContinueNextEnvelope,
  setCommentError,
} from 'actions';
import LeavePageModal from 'components/LeavePageModal';
import LoadingIndicator from 'components/LoadingIndicator';
import Modal from 'components/Modal';
import SplitButton from 'components/SplitButton';
import { MODAL_BACKGROUND } from 'constants/common';
import { resourceQueryParamName } from 'constants/resourceQueryNames';
import { defaultReviewer } from 'constants/reviewSets';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getCustomerDomain, getRequireComment, getUser } from 'selectors/auth';
import { envelopeListContentState } from 'selectors/envelopeListView';
import { getDeclaredStatus, getHasChanges, getSelectedReviewer } from 'selectors/envelopeReview';
import {
  getEnvelope,
  getEnvelopes,
  getFetchSingleEnvelopeLoading,
  getNextSingleEnvelope,
  getPreviousSingleEnvelope,
  getReviewAndContinueLoading,
  getSingleEnvelopeComments,
} from 'selectors/envelopes';
import { getNavParamsByResourceMemo } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';
import { useHistory } from 'utils/urls';

type ComponentProps = {
  commentValue: string;
  setCommentValue: Dispatch<SetStateAction<string>>;
  isInHeader?: boolean;
  bypassChanges: boolean;
  setBypassChanges: React.Dispatch<React.SetStateAction<boolean>>;
};

const EnvelopePrevNext: React.FC<ComponentProps> = (props) => {
  const {
    commentValue,
    setCommentValue,
    isInHeader = false,
    bypassChanges,
    setBypassChanges,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { envelopeId } = useParams<{ envelopeId: string }>();

  const [startTime, setStartTime] = useState(0);
  const [saveMode, setSaveMode] = useState<'prev' | 'next' | null>(null);
  const [showProductionModal, setShowProductionModal] = useState(false);

  /* FRO-1090 const { register, getValues } = useForm({
    defaultValues: {
      confirm: false,
    },
  });
 */
  const customerDomain = useSelector(getCustomerDomain);
  const user = useSelector(getUser);

  const envelope = useSelector((state) => getEnvelope(state, envelopeId));
  const nextEnvelope = useSelector((state) => getNextSingleEnvelope(state, envelopeId));
  const previousEnvelope = useSelector((state) => getPreviousSingleEnvelope(state, envelopeId));

  const envelopeLoading = useSelector(getFetchSingleEnvelopeLoading);
  const reviewLoading = useSelector(getReviewAndContinueLoading);

  const hasChanges = useSelector(getHasChanges);
  const envelopeDeclaredStatus = useSelector(getDeclaredStatus);
  const selectedReviewer = useSelector(getSelectedReviewer);

  const comments = useSelector((state) => getSingleEnvelopeComments(state, envelopeId));
  const requireComment = useSelector(getRequireComment);

  const navParams = useSelector((state) =>
    getNavParamsByResourceMemo(state, resourceQueryParamName.envelopes)
  );

  const { loadingAll, totalCount } = useSelector(envelopeListContentState);
  const envelopes = useSelector(getEnvelopes);

  useEffect(() => {
    if (!nextEnvelope && navParams.page_id && !loadingAll) {
      const offset = parseInt(navParams.page_id as string, 10);

      if (previousEnvelope) {
        let newOffset = offset + Math.min(25, totalCount);
        if (newOffset >= totalCount) {
          newOffset = offset;
        }

        dispatch(
          // @ts-ignore
          fetchAllEnvelopes({
            offset: newOffset,
          })
        );
      } else {
        dispatch(
          // @ts-ignore
          fetchAllEnvelopes({
            offset,
          })
        );
      }
    } else if (
      nextEnvelope &&
      !previousEnvelope &&
      navParams.page_id &&
      !loadingAll &&
      parseInt(navParams.page_id as string, 10) > 0
    ) {
      const offset = parseInt(navParams.page_id as string, 10);

      let newOffset = offset - Math.min(25, totalCount);
      if (newOffset >= totalCount) {
        newOffset = offset;
      }

      dispatch(
        // @ts-ignore
        fetchAllEnvelopes({
          offset: newOffset,
        })
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [envelopes.length, navParams, nextEnvelope]);

  useEffect(() => {
    setStartTime(performance.now());
  }, [envelopeLoading]);

  const handleNextPrevEnvelopeClick = (navigate: 'prev' | 'next' | null): void => {
    logEvent('envelope-review-next');

    /* FRO-1090 const { confirm } = getValues(); */

    // Review and continue to next envelope envelope
    if (envelopeDeclaredStatus) {
      const meta_data =
        selectedReviewer && selectedReviewer !== defaultReviewer.value
          ? { meta_data: { reviewed_by: selectedReviewer } }
          : {};

      let navigateTo: string | null = null;

      if (navigate === 'prev' && previousEnvelope) navigateTo = previousEnvelope.uuid;
      if (navigate === 'next' && nextEnvelope) navigateTo = nextEnvelope.uuid;

      if (commentValue) {
        dispatch(addCommentEnvelope({ envelopeId, value: commentValue }));
        setCommentValue('');
      }

      dispatch(
        reviewAndContinueNextEnvelope({
          nextEnvelopeId: navigateTo,
          envelopeId,
          value: envelopeDeclaredStatus,
          secondsSpent: Math.round((performance.now() - startTime) / 1000),
          ...meta_data,
        })
      );
    }
  };

  const checkIfProduction = (): boolean => {
    if (
      user.email?.includes('litlingo') &&
      envelope &&
      envelope.communications &&
      // @ts-ignore
      !envelope.communications[0].meta_data.source
    ) {
      return true;
    }
    return false;
  };

  const checkComment = (): boolean => {
    if (requireComment && envelopeDeclaredStatus === 'escalated' && comments.length === 0) {
      return true;
    }
    return false;
  };

  const handleSaveEnvelopeClick = (): void => {
    logEvent('envelope-save');

    if (checkIfProduction()) {
      setSaveMode(null);
      setShowProductionModal(true);
      return;
    }
    handleNextPrevEnvelopeClick(null);
  };

  const handleNextEnvelopeClick = (): void => {
    logEvent('envelope-next');

    setBypassChanges(true);

    setTimeout(() => {
      if (
        nextEnvelope &&
        nextEnvelope.page_id !== undefined &&
        nextEnvelope.page_id !== '' &&
        nextEnvelope.page_id !== null
      ) {
        const qp = new URLSearchParams(location.search);
        qp.set('envelopes__page_id', nextEnvelope.page_id.toString());
        qp.set('envelopes__offset', nextEnvelope.page_id.toString());
        history.pushLookup({
          routeName: 'envelope-detail',
          routeParams: { envelopeId: nextEnvelope.uuid },
          queryParams: qp.toString(),
          customerDomain,
        });
      }
    }, 100);
  };

  const handleBackEnvelopeClick = (): void => {
    logEvent('envelope-back');

    setTimeout(() => {
      if (
        previousEnvelope &&
        previousEnvelope.page_id !== undefined &&
        previousEnvelope.page_id !== '' &&
        previousEnvelope.page_id !== null
      ) {
        const qp = new URLSearchParams(location.search);
        qp.set('envelopes__page_id', previousEnvelope.page_id.toString());
        qp.set('envelopes__offset', previousEnvelope.page_id.toString());
        history.pushLookup({
          routeName: 'envelope-detail',
          routeParams: { envelopeId: previousEnvelope.uuid },
          queryParams: qp.toString(),
          customerDomain,
        });
      }
    }, 100);
  };

  const renderPreviousButton = (): JSX.Element => (
    <button
      type="button"
      onClick={handleBackEnvelopeClick}
      data-testid="next-envelope-button"
      className="button button--secondary w-27 py-1.5 h-full"
      disabled={!previousEnvelope}
    >
      <div className="flex w-full justify-center items-center font-bold">
        {reviewLoading ? <LoadingIndicator size="5" /> : 'Previous'}
      </div>
    </button>
  );

  const productionModal = (
    <>
      {MODAL_BACKGROUND}
      <div className="px-4 pb-6">
        <h2 className="text-heading-1">This is a production environment</h2>
        <p className="my-2 text-body">Do you still want to save these selections? </p>
      </div>
    </>
  );

  const renderNextSaveButton = (): JSX.Element => {
    let buttonText = 'Next';
    if (!nextEnvelope) buttonText = 'Save';

    const options = [
      {
        label: 'Save',
        action: handleSaveEnvelopeClick,
      },
      {
        label: 'Next',
        action: handleNextEnvelopeClick,
      },
    ];

    if (nextEnvelope && hasChanges) {
      return (
        <div className="h-full">
          <SplitButton
            primaryLabel="Save & Next"
            primaryAction={(): void => {
              if (checkComment()) {
                dispatch(setCommentError(true));
                return;
              }
              if (checkIfProduction()) {
                setSaveMode('next');
                setShowProductionModal(true);
                return;
              }
              handleNextPrevEnvelopeClick('next');
            }}
            primaryId="review-next-envelope"
            options={options}
            dropdownDirection={isInHeader ? 'down' : 'up'}
            buttonStyle={isInHeader ? 'secondary' : 'primary'}
          />
        </div>
      );
    }

    return (
      <button
        type="button"
        disabled={!nextEnvelope && !hasChanges}
        onClick={(): void => {
          if (checkComment()) {
            dispatch(setCommentError(true));
            return;
          }
          if (checkIfProduction() && hasChanges) {
            setSaveMode(null);
            setShowProductionModal(true);
            return;
          }
          if (hasChanges) {
            handleNextPrevEnvelopeClick(null);
          } else {
            handleNextEnvelopeClick();
          }
        }}
        id="next-envelope-button"
        data-testid="next-envelope-button"
        className={`button ${
          isInHeader ? 'button--secondary' : 'button--primary'
        } min-w-27 h-full py-1.5 focus:outline-none`}
      >
        <div
          className={`flex w-full justify-center items-center text-base font-bold ${
            isInHeader ? '' : 'text-white'
          } leading-5`}
        >
          {reviewLoading ? <LoadingIndicator size="5" /> : buttonText}
        </div>
      </button>
    );
  };

  return (
    <>
      <div
        className={`flex flex-col gap-1 bg-white border border-litlingo-gray-1 ${
          selectedReviewer !== defaultReviewer.value ? 'pt-2 pb-4 px-4' : 'p-4'
        } ${isInHeader ? 'border-t-0 py-2' : ''}`}
        style={!isInHeader ? { filter: 'drop-shadow(3px 1px 8px rgba(0, 0, 0, 0.25))' } : {}}
      >
        {/* FRO-1090 {selectedReviewer !== defaultReviewer.value && (
          <div className="flex flex-row justify-end">
            <label
              htmlFor="confirm-checkbox"
              className="flex flex-row gap-2 text-body items-center"
            >
              <div className="flex flex-row gap-1 text-small">Confirm coding</div>
              <input
                id="confirm-checkbox"
                ref={register()}
                type="checkbox"
                name="confirm"
                className="form-checkbox litlingo-checkbox h-4 w-4 transition duration-150 ease-in-out"
              />
            </label>
          </div>
        )} */}
        <div className="flex flex-row items-center justify-between gap-6 h-8">
          {renderPreviousButton()}
          {renderNextSaveButton()}
        </div>
      </div>
      {!isInHeader && (
        <LeavePageModal
          shouldBlockNavigation={hasChanges && !bypassChanges}
          title="Confirm and Exit?"
          text="Would you like to confirm your changes at this time before exiting?"
          navigateButtontext="Discard Changes"
          cancelOrActionNavigateButtontext="Confirm"
          navigateAction={(): void => handleNextPrevEnvelopeClick(null)}
        />
      )}
      {showProductionModal && (
        <Modal
          body={productionModal}
          title=" "
          style={{ 'padding-left': '0', 'padding-right': '0' }}
          okButton
          okButtonText="Yes"
          okButtonOnClick={(): void => {
            handleNextPrevEnvelopeClick(saveMode);
            setShowProductionModal(false);
          }}
          okButtonStyle="mr-4"
          cancelButton
          cancelButtonOnclick={(): void => setShowProductionModal(false)}
          cancelButtonText="Cancel"
          xButton={false}
        />
      )}
    </>
  );
};

export default EnvelopePrevNext;
