import { cleanEnvelopesState, setURLParams } from 'actions';
import { orderByNewParam } from 'actions/envelopeListView';
import useClickOutside from 'components/utils/useClickOutside';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsByResourceMemo } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  resource: string;
};

type OptionProps = { label: string; id: string; orderDesc: string; orderBy: string };

export const envelopeOrderOptions = [
  /* { label: 'Subject', id: 'subject', orderDesc: 'true', orderBy: 'subject' }, */
  { label: 'Newest', id: 'new', orderDesc: 'true', orderBy: 'created_at' },
  { label: 'Date', id: 'date', orderDesc: 'true', orderBy: 'created_at' },
  { label: 'Random', id: 'random', orderDesc: 'true', orderBy: 'random' },
  /* { label: 'Sender', id: 'sender', orderDesc: 'true', orderBy: 'user_name' },
  { label: 'Status', id: 'status', orderDesc: 'true', orderBy: 'review_value' }, */
  { label: 'Reviewed Date', id: 'reviewedDate', orderDesc: 'true', orderBy: 'reviewed_at' },
];

const SortFilter: React.FC<ComponentProps> = ({ resource }) => {
  const dispatch = useDispatch();

  const navParams = useSelector((state) => getNavParamsByResourceMemo(state, resource));

  const [isOpen, setIsOpen] = useState(false);
  const [currentLabel, setCurrentLabel] = useState<string | null>(null);

  useEffect(() => {
    const label = envelopeOrderOptions.find((o) => o.orderBy === navParams.order_by)?.label ?? '';
    setCurrentLabel(label);

    if (label !== 'Newest' && label !== '') {
      dispatch(orderByNewParam(true));
    }
  }, [dispatch, navParams.order_by]);

  // click outside functionality
  const handleClickOutside = useCallback(() => setIsOpen(false), [setIsOpen]);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, handleClickOutside);

  const handleButtonClick = (): void => setIsOpen(!isOpen);

  const handleInputChange = (option: OptionProps): void => {
    logEvent('envelopes-list-sort');
    const { orderBy, label, id, orderDesc } = option;

    if (id === 'new') {
      dispatch(orderByNewParam(false));
    } else {
      dispatch(orderByNewParam(true));
    }

    setCurrentLabel(label);
    dispatch(cleanEnvelopesState());

    dispatch(
      setURLParams({
        [`${resource}__order_by`]: orderBy,
        [`${resource}__order_desc`]: orderDesc,
        [`${resource}__offset`]: '0',
      })
    );

    handleButtonClick();
  };

  const getOption = (option: OptionProps): JSX.Element => (
    <button
      type="button"
      data-testid={`envelope-list-sort-${option.label}`}
      key={option.id}
      className={`w-full border-litlingo-gray-2 p-2 focus:outline-none hover:bg-litlingo-gray-1 ${
        option.label === currentLabel ? 'font-bold cursor-default' : 'cursor-pointer'
      }`}
      onClick={(): void => handleInputChange(option)}
      disabled={option.label === currentLabel}
    >
      <div className="flex">
        <span>{option.label}</span>
      </div>
    </button>
  );

  return (
    <div ref={wrapperRef} className="w-full relative text-body">
      <button
        type="button"
        className={`flex h-8 w-full justify-between items-center px-2 text-white bg-litlingo-success-light bg-opacity-10 rounded focus:outline-none disabled:opacity-50 ${
          currentLabel === 'Newest' ? 'italic' : ''
        }`}
        id="options-menu"
        data-testid="envelope-list-sort-button"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={handleButtonClick}
      >
        {currentLabel === 'Newest' ? 'Sort By' : currentLabel}
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="flex-none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.49026 8.44392L1.83398 3L0 4.91123L7.49035 12.0926L15 4.91123L13.166 3L7.49026 8.44392Z"
            fill="currentColor"
          />
        </svg>
      </button>

      {isOpen && (
        <div
          data-testid="dropdown-menu"
          className="origin-bottom-left absolute mt-1 w-full rounded shadow-lg z-50"
        >
          <div className="rounded bg-white shadow-xs overflow-hidden">
            <div
              role="menu"
              data-testid="options-container"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              {envelopeOrderOptions &&
                envelopeOrderOptions.map((item) => <div key={item.label}>{getOption(item)}</div>)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SortFilter;
