/* eslint-disable max-lines */
import {
  selectCampaignEnvelopeReview,
  selectCommunication,
  selectRuleEnvelopeReview,
  selectSection,
} from 'actions';
import KeyActionsIcons from 'components/KeyActionsIcons/KeyActionsIconsRedesign';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { EnvelopeReviewRule, SectionType } from 'reducers/envelopeReview';
import { getCustomerDomain } from 'selectors/auth';
import {
  CampaignWithRules,
  getCampaignsRulesForHistory,
  getLastVersion,
  getSelectedCampaign,
  getSelectedRule,
  getSelectedSection,
  getSummaryTag,
} from 'selectors/envelopeReview';
import { getEnvelope } from 'selectors/envelopes';
import { useSelector } from 'store';
import { reverse, useHistory } from 'utils/urls';
import CampaignRules from './CampaignRules';

const sortCampaigns = (
  [, a]: [string, CampaignWithRules],
  [, b]: [string, CampaignWithRules]
): number => {
  const aPrio = a.campaignPriority && a.campaignPriority !== 0 ? a.campaignPriority : 1000;
  const bPrio = b.campaignPriority && b.campaignPriority !== 0 ? b.campaignPriority : 1000;

  if (aPrio < bPrio) {
    return -1;
  }
  if (bPrio > aPrio) {
    return 1;
  }

  if (a.campaignName < b.campaignName) {
    return -1;
  }
  if (a.campaignName > b.campaignName) {
    return 1;
  }

  return 0;
};

const EnvelopeMessageHistory: React.FC = () => {
  const section: SectionType = 'history';

  const dispatch = useDispatch();
  const history = useHistory();
  const { envelopeId } = useParams<{ envelopeId: string }>();

  const envelope = useSelector((state) => getEnvelope(state, envelopeId));
  const customerDomain = useSelector(getCustomerDomain);

  const selectedSection = useSelector(getSelectedSection);
  const selectedCampaign = useSelector(getSelectedCampaign);
  const selectedRule = useSelector(getSelectedRule);

  const campaignsRules = useSelector(getCampaignsRulesForHistory);
  const lastCommunication = useSelector(getLastVersion);
  const summaryTag = useSelector((state) => getSummaryTag(state, lastCommunication?.uuid || ''));

  const selectCampaign = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: string): void => {
    e.stopPropagation();

    if (!campaignsRules) return;
    dispatch(selectCommunication({ communication: lastCommunication }));
    dispatch(selectCampaignEnvelopeReview(id));
    dispatch(selectRuleEnvelopeReview(null));
    dispatch(selectSection(section));
  };

  const selectRuleCampaign = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    rule: EnvelopeReviewRule,
    campaignId: string
  ): void => {
    e.stopPropagation();
    dispatch(selectCommunication({ communication: lastCommunication }));
    dispatch(selectCampaignEnvelopeReview(campaignId));
    dispatch(selectRuleEnvelopeReview(rule));
    dispatch(selectSection(section));

    let route = {};

    if ('rule_group_uuid' in rule && rule.rule_group_uuid) {
      route = {
        routeName: 'global-rule-group-manager',
        routeParams: { ruleId: rule.rule_group_uuid },
        customerDomain,
      };
    } else {
      route = {
        routeName: 'rule-manager-campaign',
        routeParams: { ruleId: rule.uuid, campaignId },
        customerDomain,
      };
    }

    const path = reverse(route);

    if ((e.ctrlKey || e.metaKey) && selectedRule?.uuid === rule.uuid) {
      window.open(path, '_blank');
      return;
    }

    const element = document.getElementById(rule.uuid);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    if (selectedRule?.uuid === rule.uuid && selectedSection === section) {
      history.pushLookup(route);
    }
  };

  const handleMainSectionClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    if (campaignsRules) {
      dispatch(selectCommunication({ communication: lastCommunication }));
      selectCampaign(e, Object.keys(campaignsRules)[0]);
    }
  };

  if (!envelope || !campaignsRules || Object.keys(campaignsRules).length === 0) return null;

  return (
    <div className="w-full flex flex-col bg-white border border-litlingo-gray-0.5 overflow-hidden text-small">
      <button
        type="button"
        className={`h-8 w-full flex flex-row items-center justify-between px-3 focus:outline-none ${
          selectedSection === section ? 'bg-litlingo-highlight' : 'bg-white'
        }`}
        onClick={(e): void => handleMainSectionClick(e)}
      >
        <span className="font-bold">History</span>
        <div className="flex justify-start items-center">
          {envelope.action_summary && (
            <KeyActionsIcons
              // @ts-ignore
              keys={envelope.action_summary.map((a) => (a.type ? a.type : a.kind))}
              envelopeUuid={envelope.uuid}
              summaryTag={summaryTag}
            />
          )}
        </div>
      </button>
      <div className="flex flex-col">
        {campaignsRules &&
          Object.entries(campaignsRules)
            .sort(sortCampaigns)
            .map(([key, value]) => (
              <CampaignRules
                key={key}
                campaignId={key}
                campaignRules={value}
                selectedCampaign={selectedCampaign}
                selectCampaign={selectCampaign}
                selectRuleCampaign={selectRuleCampaign}
                section={section}
              />
            ))}
      </div>
    </div>
  );
};

export default EnvelopeMessageHistory;
