import { createAction } from '@reduxjs/toolkit';
import type {
  AnnotatorRelationship,
  API,
  Category,
  Customer,
  ErrorObject,
  MRule,
  MRuleConfigNode,
  MRuleGroup,
  MRuleRevision,
  NormalizedAnnotator,
  NormalizedResource,
  RuleGroup,
  RuleTestCasesSummary,
  TestCommunication,
  UUID,
} from 'types';

export type EditRulePayload = {
  id: UUID;
  data: Partial<MRuleGroup>;
};
export const editRule = createAction<EditRulePayload>('RULE_GROUP/EDIT');

export type UpdateRuleGroupPayload = {
  ruleGroup: MRuleGroup;
};
export const updateRuleGroup = createAction<UpdateRulePayload>('RULE_GROUP/UPDATE_RULE_GROUP');
export const saveRuleGroupRequest = createAction('RULE_GROUP/SAVE_RULE_GROUP_REQUEST');
export const saveRuleGroupFailure = createAction<ErrorObject>('RULE_GROUP/SAVE_RULE_GROUP_FAILURE');
export const saveRuleGroupSuccess = createAction<MRuleGroup>('RULE_GROUP/SAVE_RULE_GROUP_SUCCESS');

export type UpdateRulePayload = {
  rule: MRuleRevision;
};
export const updateRule = createAction<UpdateRulePayload>('RULE_GROUP/UPDATE_RULE');
export const saveRuleRequest = createAction('RULE_GROUP/SAVE_REQUEST');
export const saveRuleFailure = createAction<ErrorObject>('RULE_GROUP/SAVE_FAILURE');
export const saveRuleSuccess = createAction<MRuleRevision>('RULE_GROUP/SAVE_SUCCESS');

type CreateRuleGroupPayload = {
  name: string;
  navigate?: boolean;
};

export const createRuleGroup = createAction<CreateRuleGroupPayload>('RULE_GROUP/CREATE_RULE_GROUP');
export const createRuleGroupRequest = createAction('RULE_GROUP/CREATE_RULE_GROUP_REQUEST');
export const createRuleGroupFailure = createAction<ErrorObject>(
  'RULE_GROUP/CREATE_RULE_GROUP_FAILURE'
);
export const createRuleGroupSuccess = createAction('RULE_GROUP/CREATE_RULE_GROUP_SUCCESS');

// starship review
export type CloneRulePayload = {
  ruleOutcomeId: UUID;
  campaignId: UUID;
};

export const cloneRule = createAction<CloneRulePayload>('RULE_GROUP/CLONE');
export const cloneRuleRequest = createAction('RULE_GROUP/CLONE_REQUEST');
export const cloneRuleFailure = createAction<ErrorObject>('RULE_GROUP/CLONE_FAILURE');
export const cloneRuleSuccess = createAction('RULE_GROUP/CLONE_SUCCESS');

export type FetchSingleRulePayload = {
  ruleId: UUID;
};

export const fetchSingleRuleGroup = createAction<FetchSingleRulePayload>('RULE_GROUP/FETCH');
export const fetchSingleRuleGroupRequest = createAction('RULE_GROUP/FETCH_REQUEST');
export const fetchSingleRuleGroupFailure = createAction<ErrorObject>('RULE_GROUP/FETCH_FAILURE');
export const fetchSingleRuleGroupSuccess = createAction<{
  ruleGroup: MRuleGroup;
  ruleRevision: MRuleRevision;
}>('RULE_GROUP/FETCH_SUCCESS');

export type FetchAllRulesPayload = {
  name?: string;
  limit?: string;
  // eslint-disable-next-line camelcase
  customer_uuid?: string;
};

export const fetchAllRulesDebounced = createAction<FetchAllRulesPayload>(
  'RULE_GROUPS/DEBOUNCED_FETCH'
);
export const fetchAllRuleGroups = createAction<FetchAllRulesPayload>('RULE_GROUPS/FETCH');
export const fetchAllRuleGroupsWithSummary = createAction<FetchAllRulesPayload>(
  'RULE_GROUPS_SUMMARY/FETCH'
);
export const fetchFilterRules = createAction<FetchAllRulesPayload>(
  'RULE_GROUPS/FETCH_FILTER_REQUEST'
);
export const fetchAllRuleGroupsRequest = createAction('RULE_GROUPS/FETCH_REQUEST');
export const fetchAllRuleGroupsFailure = createAction<ErrorObject>('RULE_GROUPS/FETCH_FAILURE');
export const fetchAllRuleGroupsSuccess = createAction<API.WrappedAPIResponse<RuleGroup>>(
  'RULE_GROUPS/FETCH_SUCCESS'
);

type FetchRulesForFilterPillsPayload = { ruleIds: string[] };

export const fetchRulesForFilterPills =
  createAction<FetchRulesForFilterPillsPayload>('RULE_GROUPS/FETCH_BY_ID');
export const fetchRulesForFilterPillsRequest = createAction('RULE_GROUPS/FETCH_BY_ID_REQUEST');
export const fetchRulesForFilterPillsSuccess = createAction<API.WrappedAPIResponse<RuleGroup>>(
  'RULE_GROUPS/FETCH_BY_ID_SUCCESS'
);
export const fetchRulesForFilterPillsFailure = createAction<ErrorObject>(
  'RULE_GROUPS/FETCH_BY_ID_FAILURE'
);

export type DeleteRulePayload = {
  ruleId: UUID;
};

export const deleteRule = createAction<DeleteRulePayload>('RULE_GROUP/DELETE');
export const deleteRuleRequest = createAction<string>('RULE_GROUP/DELETE_REQUEST');
export const deleteRuleSuccess = createAction<string>('RULE_GROUP/DELETE_SUCCESS');
export const deleteRuleFailure = createAction<ErrorObject>('RULE_GROUP/DELETE_FAILURE');

export const saveRelationshipsSuccess = createAction<
  NormalizedResource<Omit<AnnotatorRelationship, 'configId'>>
>('RELATIONSHIP/SAVE_SUCCESS');
export const fetchRelationshipsSuccess = createAction<
  NormalizedResource<Omit<AnnotatorRelationship, 'configId'>>
>('RELATIONSHIP/FETCH_SUCCESS');

export type UpdateConfigGroupsPayload = {
  parentId: UUID;
  newConfigId: UUID;
};
export const updateConfigGroups = createAction<UpdateConfigGroupsPayload>(
  'CONFIG/UPDATE_CONFIG_GROUPS'
);
export const createRuleConfig = createAction<NormalizedResource<MRuleConfigNode>>('CONFIG/CREATE');
export const saveRuleConfigSuccess =
  createAction<NormalizedResource<MRuleConfigNode>>('CONFIG/SAVE_SUCCESS');
export const fetchRuleConfigSuccess =
  createAction<NormalizedResource<MRuleConfigNode>>('CONFIG/FETCH_SUCCESS');

export type AddAnnotationMatcherToRulePayload = {
  annotator: NormalizedAnnotator;
  rule: MRuleRevision;
  dropIndex?: number;
};
export const addAnnotationMatcherToRule = createAction<AddAnnotationMatcherToRulePayload>(
  'RULE_GROUP/ADD_ANNOTATION_MATCHER_TO_RULE'
);

export type selectRuleType = {
  rule: MRuleRevision | null;
};
export const selectRule = createAction<selectRuleType>('RULE_GROUP/SELECT');

export type FetchRuleChangelogType = {
  ruleId: UUID;
  auditlogUuid: UUID;
  version: string;
};
export const fetchRuleChangelog = createAction<FetchRuleChangelogType>(
  'RULE_GROUP/FETCH_CHANGELOG'
);

type ScheduleComparePayload = {
  savedSearchId: UUID;
  ruleUuids: UUID[];
};
export const scheduleCompare = createAction<ScheduleComparePayload>(
  'RULE_GROUPS/SCHEDULE_COMPARE_FULFILL'
);
export const scheduleCompareRequest = createAction('RULE_GROUPS/SCHEDULE_COMPARE_REQUEST');
export const scheduleCompareSuccess = createAction('RULE_GROUPS/SCHEDULE_COMPARE_SUCCESS');
export const scheduleCompareFailure = createAction<ErrorObject>(
  'RULE_GROUPS/SCHEDULE_COMPARE_FAILURE'
);
export const scheduleCompareFulfill = createAction('RULE_GROUPS/SCHEDULE_COMPARE_FULFILL');

export type FetchRuleTestCasesPayload = {
  rule_uuids: UUID;
};

export const fetchTestCaseSummaryRuleGroup = createAction<FetchRuleTestCasesPayload>(
  'TEST_CASE_SUMMARY_RULE_GROUP/FETCH'
);
export const fetchTestCaseSummaryRuleGroupRequest = createAction(
  'TEST_CASE_SUMMARY_RULE_GROUP/FETCH_REQUEST'
);
export const fetchTestCaseSummaryRuleGroupSuccess = createAction<RuleTestCasesSummary>(
  'TEST_CASE_SUMMARY_RULE_GROUP/FETCH_SUCCESS'
);
export const fetchTestCaseSummaryRuleGroupFailure = createAction<ErrorObject>(
  'TEST_CASE_SUMMARY_RULE_GROUP/FETCH_FAILURE'
);

// starship review
export type PublisRuleVersion = {
  ruleId: UUID;
  version: string;
};
export const publishRuleVersion = createAction<PublisRuleVersion>('RULE_GROUPS/PUBLISH_VERSION');
export const publishRuleVersionRequest = createAction('RULE_GROUPS/PUBLISH_VERSION_REQUEST');
export const publishRuleVersionSuccess = createAction<MRule>('RULE_GROUPS/PUBLISH_VERSION_SUCCESS');
export const publishRuleVersionFailure = createAction<ErrorObject>(
  'RULE_GROUPS/PUBLISH_VERSION_FAILURE'
);

// starship review
export type UndoRuleVersion = {
  ruleId: UUID;
};
export const undoRuleVersion = createAction<UndoRuleVersion>('RULE_GROUPS/UNDO_VERSION');
export const undoRuleVersionRequest = createAction('RULE_GROUPS/UNDO_VERSION_REQUEST');
export const undoRuleVersionSuccess = createAction<MRule>('RULE_GROUPS/UNDO_VERSION_SUCCESS');
export const undoRuleVersionFailure = createAction<ErrorObject>('RULE_GROUPS/UNDO_VERSION_FAILURE');

export const selectAnnotators = createAction<string[]>('RULE_GROUP/SET_SELECTED_ANOTATORS');

export const setShowHighlights = createAction<boolean>('RULE_GROUP/SHOW_HIGHLIGHTS');

export const setShowHeatmap = createAction<boolean>('RULE_GROUP/SHOW_HEATMAP');

export const setCurrentTestRuleId = createAction<string>('RULE_GROUP/TEST_RULE_ID');

export const annotatorPosition = createAction<string | null>('RULE_GROUP/ANNOTATOR_POSITION');

export const setToAnnotator = createAction<boolean>('RULE_GROUP/SET_TO_ANNOTATOR');

type PreviewRuleGroup = {
  body: string;
  platform: string;
  ruleRevisionId: string;
  ruleGroupId: string;
  force_v2_graphs?: boolean;
  customer_uuid?: string;
};

export const previewRuleGroup = createAction<PreviewRuleGroup>('RULE_GROUP/PREVIEW');
export const previewRuleGroupRequest = createAction('RULE_GROUP/PREVIEW_REQUEST');
export const previewRuleGroupSuccess = createAction<TestCommunication>(
  'RULE_GROUP/PREVIEW_SUCCESS'
);
export const previewRuleGroupFailure = createAction<ErrorObject>('RULE_GROUP/PREVIEW_FAILURE');
export const previewRuleGroupFulfill = createAction('RULE_GROUP/PREVIEW_FULFILL');

export const fetchRuleCustomers = createAction<string>('RULE_GROUP/FETCH_CUSTOMERS');
export const fetchRuleCustomersRequest = createAction('RULE_GROUP/FETCH_CUSTOMERS_REQUEST');
export const fetchRuleCustomersSuccess = createAction<Customer[]>(
  'RULE_GROUP/FETCH_CUSTOMERS_SUCCESS'
);
export const fetchRuleCustomersFailure = createAction<ErrorObject>(
  'RULE_GROUP/FETCH_CUSTOMERS_FAILURE'
);
export const fetchRuleCustomersFulfill = createAction('RULE_GROUP/FETCH_CUSTOMERS_FULFILL');

export const clearRuleGroupTest = createAction('RULE_GROUP/CLEAR_RULE_GROUP_TEST');

export type FetchRuleCategoryPayload = {
  ruleId: UUID;
};

export const fetchRuleCategory = createAction<FetchRuleCategoryPayload>('CATEGORIES/RULE_CATEGORY');
export const fetchRuleCategoryRequest = createAction('CATEGORIES/RULE_CATEGORY_REQUEST');
export const fetchRuleCategorySuccess = createAction<Category>('CATEGORIES/RULE_CATEGORY_SUCCESS');
export const fetchRuleCategoryFailure = createAction<ErrorObject>(
  'CATEGORIES/RULE_CATEGORY_FAILURE'
);
export const fetchRuleCategoryFulfill = createAction('CATEGORIES/RULE_CATEGORY_FULFILL');

export const setShowUtilization = createAction<boolean>('RULE_GROUP/SHOW_UTILIZATION');

export const setHighlightCustomerScope = createAction<string | null>(
  'RULE_GROUP/HIGHLIGHT_CUSTOMER_SCOPE'
);

export const toggleShowV2Graph = createAction('RULE_GROUP/TOGGLE_SHOW_V@_GRAPH');

type FetchRuleContextPayload = {
  uuids: string[];
};

export const fetchRuleContext = createAction<FetchRuleContextPayload>('RULE_REVISIONS/FETCH_RULES');
export const fetchRuleContextRequest = createAction('RULE_REVISIONS/FETCH_RULES_REQUEST');
export const fetchRuleContextSuccess = createAction<RuleGroup[]>(
  'RULE_REVISIONS/FETCH_RULES_SUCCESS'
);
export const fetchRuleContextFailure = createAction<ErrorObject>(
  'RULE_REVISIONS/FETCH_RULES_FAILURE'
);
