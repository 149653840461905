import { addAndRemoveValuesToTree } from 'actions';
import { SEARCH_ICON } from 'constants/filterIcons';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getNavParamsFromTree } from 'selectors/nav';
import { useSelector } from 'store';
import logEvent from 'utils/analytics';

type ComponentProps = {
  field?: string;
  placeholder?: string;
  className?: string;
  logEventMessage?: string;
};

const EnvelopeListSearchInput: React.FC<ComponentProps> = (props) => {
  const { field, placeholder, className, logEventMessage } = props;

  const dispatch = useDispatch();

  const { broad_search: value } = useSelector(getNavParamsFromTree) as {
    broad_search: string[];
    subject: string;
  };

  const [body, setBody] = useState<string>(value?.[0] || '');

  useEffect(() => {
    setBody(value?.[0] || '');
  }, [value]);

  const performSearch = (): void => {
    if (logEventMessage) logEvent(`${logEventMessage}-${body}`);

    if (body.trim()) {
      dispatch(
        addAndRemoveValuesToTree({
          valueToAdd: {
            field: field || 'search',
            value: body.trim(),
            label: body.trim(),
            isSingleValue: true,
          },
          fieldsToRemove: ['subject'],
        })
      );
    }
  };

  const handleSearch = (): void => {
    if (!body.length) return;
    performSearch();
  };

  const handleKeyDownSearch = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (!body || body.length === 0) return;
    if (event.key === 'Enter') {
      event.preventDefault();
      performSearch();
    }
  };

  return (
    <div
      className={`flex flex-row justify-start items-center w-full h-8 rounded bg-white border border-litlingo-gray-2 text-body ${className}`}
    >
      <input
        id="search"
        name="search"
        className="h-full w-full px-2 py-1.5 text-litlingo-gray-6 font-normal rounded text-base placeholder-litlingo-gray-6 placeholder-italic"
        placeholder={placeholder || 'Search Keyword(s)'}
        onChange={(event): void => {
          setBody(event.target.value);
        }}
        onKeyDown={handleKeyDownSearch}
        value={body}
        autoComplete="off"
      />
      <button
        type="button"
        className="w-8 h-8 flex items-center justify-center border-t border-b border-litlingo-gray-2 rounded-r bg-litlingo-secondary-80 focus:outline-none"
        onClick={handleSearch}
      >
        <div className="w-5 flex-shrink-0">{SEARCH_ICON()}</div>
      </button>
    </div>
  );
};

export default EnvelopeListSearchInput;
