import type { PayloadAction } from '@reduxjs/toolkit';
import { createReducer } from '@reduxjs/toolkit';
import {
  deleteEnvelopeActionPayload,
  deleteEnvelopeActionSuccess,
  fetchEnvelopeActionSuccess,
  updateEnvelopeActionSuccess,
} from 'actions/envelopeActions';
import {
  FetchAllEnvelopesSuccessPayload,
  FetchEnvelopeThreadSuccessPayload,
  FetchFlaggedTextSuccessPayload,
  MarkEnvelopeAsReadPayload,
  SelectedFieldPayload,
  SelectedFilterPayload,
  addActionsBulkEnvelopeFailure,
  addActionsBulkEnvelopeRequest,
  addActionsBulkEnvelopeSuccess,
  addBulkAllEnvelopeTagsFailure,
  addBulkAllEnvelopeTagsRequest,
  addBulkAllEnvelopeTagsSuccess,
  addBulkEnvelopeTagsFailure,
  addBulkEnvelopeTagsRequest,
  addBulkEnvelopeTagsSuccess,
  addBulkEnvelopeTagsSuccessAsync,
  addCommentEnvelopeFailure,
  addCommentEnvelopeRequest,
  addCommentEnvelopeSuccess,
  addEnvelopeTagFailure,
  addEnvelopeTagFulfill,
  addEnvelopeTagRequest,
  addEnvelopeTagSuccess,
  addNotifyEnvelopeFailure,
  addNotifyEnvelopeRequest,
  addNotifyEnvelopeSuccess,
  addSelectedField,
  addSelectedFilter,
  bulkReviewEnvelopesRequest,
  bulkReviewEnvelopesSuccess,
  cleanEnvelopesState,
  clearSelectedFields,
  clearSelectedFilters,
  clearTree,
  exportEnvelopesSearch,
  exportEnvelopesSearchFailure,
  exportEnvelopesSearchRequest,
  exportEnvelopesSearchSuccess,
  exportNgramFailure,
  exportNgramRequest,
  exportNgramSuccess,
  extractRecipientDomainsFailure,
  extractRecipientDomainsRequest,
  extractRecipientDomainsSuccess,
  extractSenderDomainsFailure,
  extractSenderDomainsRequest,
  extractSenderDomainsSuccess,
  fetchAggsRequest,
  fetchAggsSuccess,
  fetchAllEnvelopes,
  fetchAllEnvelopesFailure,
  fetchAllEnvelopesPrototypeSuccess,
  fetchAllEnvelopesRequest,
  fetchAllEnvelopesSuccess,
  fetchEnvelopeAttachmentsFailure,
  fetchEnvelopeAttachmentsFulfill,
  fetchEnvelopeAttachmentsRequest,
  fetchEnvelopeAttachmentsSuccess,
  fetchEnvelopeEsDocsAsIndexedFailure,
  fetchEnvelopeEsDocsAsIndexedRequest,
  fetchEnvelopeEsDocsAsIndexedSuccess,
  fetchEnvelopeEsDocsFailure,
  fetchEnvelopeEsDocsRequest,
  fetchEnvelopeEsDocsSuccess,
  fetchEnvelopeThreadFailure,
  fetchEnvelopeThreadRequest,
  fetchEnvelopeThreadSuccess,
  fetchFlaggedTextFailure,
  fetchFlaggedTextFulfill,
  fetchFlaggedTextRequest,
  fetchFlaggedTextSuccess,
  fetchMoreLikeThisFulfill,
  fetchMoreLikeThisRequest,
  fetchSingleEnvelopeFailure,
  fetchSingleEnvelopeRequest,
  fetchSingleEnvelopeSuccess,
  markEnvelopeAsRead,
  markEnvelopeAsReadFailure,
  markEnvelopeAsReadSuccess,
  removeBulkEnvelopeTagsSuccess,
  removeEnvelopeTagFailure,
  removeEnvelopeTagFulfill,
  removeEnvelopeTagRequest,
  removeEnvelopeTagSuccess,
  removeSelectedField,
  removeSelectedFilter,
  reprocessBulkFailure,
  reprocessBulkRequest,
  reprocessBulkSuccess,
  reprocessEnvelopeFailure,
  reprocessEnvelopeRequest,
  reprocessEnvelopeSuccess,
  requestTreeFiltersToogle,
  reviewAndContinueNextEnvelopeFulfill,
  reviewAndContinueNextEnvelopeRequest,
  reviewEnvelopeFailure,
  reviewEnvelopeRequest,
  reviewEnvelopeSuccess,
  setTree,
  starEnvelopeFailure,
  starEnvelopeRequest,
  starEnvelopeSuccess,
} from 'actions/envelopes';
import type {
  Attachment,
  CommunicationEnvelope,
  CommunicationEnvelopeEsDocs,
  CommunicationEnvelopeEsDocsAsIndexed,
  CommunicationEnvelopeSummary,
  EnvelopeAction,
  ErrorObject,
  MoreLikeThisPoint,
  NormalizedResource,
  UUID,
} from 'types';
import { DataNode, Operator, Tree, ValueNode } from 'utils/parserTree';
import { v4 } from 'uuid';

export type EnvelopesState = {
  summaries: NormalizedResource<CommunicationEnvelopeSummary>; // call to get envelope list
  envelopes: NormalizedResource<CommunicationEnvelope>; // call to single envelope
  listIds: UUID[];
  flaggedTextlistIds: UUID[];
  envelopeThread: NormalizedResource<CommunicationEnvelopeSummary>;
  envelopeThreadCount: number;
  error: ErrorObject | null;
  loading: string[];
  count: number;
  esDocs: CommunicationEnvelopeEsDocs;
  esDocsAsIndexed: CommunicationEnvelopeEsDocsAsIndexed;
  moreLikeThis: MoreLikeThisPoint[];
  selectedFilters: ValueNode[];
  selectedFields: DataNode[];
  filtersTree: Tree;
  requestTreeFilters: boolean;
  aggs?: {
    [key: string]: {
      [key: string]: {
        count: {
          buckets: { key: string; doc_count: number }[];
        };
        doc_count: number;
      };
    };
  };
};

export type EnvelopesReducer<P = void> = (
  state: EnvelopesState,
  payload: PayloadAction<P>
) => EnvelopesState;

const defaultState: EnvelopesState = {
  summaries: {},
  envelopes: {},
  listIds: [],
  flaggedTextlistIds: [],
  envelopeThread: {},
  envelopeThreadCount: 0,
  error: null,
  loading: [],
  count: 0,
  esDocs: {} as CommunicationEnvelopeEsDocs,
  esDocsAsIndexed: {} as CommunicationEnvelopeEsDocsAsIndexed,
  moreLikeThis: [],
  selectedFilters: [],
  selectedFields: [],
  filtersTree: { op: 'and', data: [], id: v4() },
  requestTreeFilters: true,
};

const handleFetchAllEnvelopesRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchAllEnvelopesRequest.toString()],
});

const handleFetchAllEnvelopesSuccess: EnvelopesReducer<FetchAllEnvelopesSuccessPayload> = (
  state,
  { payload }
) => {
  const envelopes: NormalizedResource<CommunicationEnvelopeSummary> = {};
  const ids: UUID[] = [];

  payload.records.forEach((item) => {
    envelopes[item.envelope.uuid] = {
      ...item,
      noMatchEvent: state.summaries[item.envelope.uuid]
        ? state.summaries[item.envelope.uuid].noMatchEvent
        : {},
      events: state.summaries[item.envelope.uuid]
        ? state.summaries[item.envelope.uuid].events
        : item.events,
    };
    ids.push(item.envelope.uuid);
  });

  const newState = {
    ...state,
    summaries: envelopes,
    loading: state.loading.filter((s) => s !== fetchAllEnvelopesRequest.toString()),
    listIds: ids,
    flaggedTextlistIds: state.flaggedTextlistIds.filter((uuid) => ids.includes(uuid)),
    count: payload.count,
  };

  // @ts-ignore
  if (payload.aggs) {
    // @ts-ignore
    newState.aggs = { ...state.aggs, [payload.expId]: payload.aggs };
  }
  return newState;
};

const handleFetchAllEnvelopesPrototypeSuccess: EnvelopesReducer<FetchAllEnvelopesSuccessPayload> = (
  state,
  { payload }
) => {
  const envelopes: NormalizedResource<CommunicationEnvelopeSummary> = {};
  const ids: UUID[] = [];

  payload.records.forEach((item) => {
    envelopes[item.envelope.uuid] = {
      ...item,
      noMatchEvent: state.summaries[item.envelope.uuid]
        ? state.summaries[item.envelope.uuid].noMatchEvent
        : {},
      events: state.summaries[item.envelope.uuid]
        ? state.summaries[item.envelope.uuid].events
        : item.events,
    };
    ids.push(item.envelope.uuid);
  });

  return {
    ...state,
    summaries: payload?.prepend
      ? { ...envelopes, ...state.summaries }
      : { ...state.summaries, ...envelopes },
    loading: state.loading.filter((s) => s !== fetchAllEnvelopesRequest.toString()),
    listIds: payload?.prepend ? [...ids, ...state.listIds] : [...state.listIds, ...ids],
    count: payload.count,
  };
};

const handleFetchAllEnvelopesFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchAllEnvelopesRequest.toString()),
});

const handleFetchSingleEnvelopeRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchSingleEnvelopeRequest.toString()],
});

const handleFetchSingleEnvelopeSuccess: EnvelopesReducer<CommunicationEnvelope> = (
  state,
  { payload }
) => {
  const normalizedEnvelopes: NormalizedResource<CommunicationEnvelope> = {};
  normalizedEnvelopes[payload.uuid] = payload;
  return {
    ...state,
    envelopes: { ...state.envelopes, ...normalizedEnvelopes },
    loading: state.loading.filter((s) => s !== fetchSingleEnvelopeRequest.toString()),
  };
};

const handleFetchSingleEnvelopeFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchSingleEnvelopeRequest.toString()),
});

const handleFetchEnvelopeThreadRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchEnvelopeThreadRequest.toString()],
});

const handleFetchEnvelopeThreadSuccess: EnvelopesReducer<FetchEnvelopeThreadSuccessPayload> = (
  state,
  { payload }
) => {
  const envelopes: NormalizedResource<CommunicationEnvelopeSummary> = {};

  payload.data.records.forEach((item) => {
    envelopes[item.envelope.uuid] = {
      ...item,
      events: state.summaries[item.envelope.uuid]
        ? state.summaries[item.envelope.uuid].events
        : item.events,
    };
  });

  return {
    ...state,
    envelopeThread: payload.offset === 0 ? envelopes : { ...state.envelopeThread, ...envelopes },
    envelopeThreadCount: payload.data.count,
    loading: state.loading.filter((s) => s !== fetchEnvelopeThreadRequest.toString()),
  };
};

const handleFetchEnvelopeThreadFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchEnvelopeThreadRequest.toString()),
});

const handleStarEnvelopeRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, starEnvelopeRequest.toString()],
});

const handleStarEnvelopeSuccess: EnvelopesReducer<CommunicationEnvelope> = (state, { payload }) => {
  const item = state.envelopes[payload.uuid] || {};
  const itemSummary = state.summaries[payload.uuid] || {};
  const updatedEnvelope = { [payload.uuid]: { ...item, ...payload } };
  const updatedSummary = {
    [payload.uuid]: { ...itemSummary, envelope: { ...itemSummary.envelope, ...payload } },
  };

  return {
    ...state,
    envelopes: { ...state.envelopes, ...updatedEnvelope },
    summaries: { ...state.summaries, ...updatedSummary },
    loading: state.loading.filter((s) => s !== starEnvelopeRequest.toString()),
  };
};

const handleStarEnvelopeFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== starEnvelopeRequest.toString()),
});

const handleAddEnvelopeTagRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addEnvelopeTagRequest.toString()],
});

const handleAddEnvelopeTagSuccess: EnvelopesReducer<CommunicationEnvelope> = (
  state,
  { payload }
) => {
  const item = state.envelopes[payload.uuid] || {};
  const itemSummary = state.summaries[payload.uuid] || {};
  const updatedEnvelope = {
    [payload.uuid]: { ...item, tags: payload.tags, action_summary: payload.action_summary },
  };
  const updatedSummary = {
    [payload.uuid]: { ...itemSummary, envelope: { ...itemSummary.envelope, tags: payload.tags } },
  };

  return {
    ...state,
    envelopes: { ...state.envelopes, ...updatedEnvelope },
    summaries: { ...state.summaries, ...updatedSummary },
  };
};

const handleAddEnvelopeTagFulfill: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== addEnvelopeTagRequest.toString()),
});

const handleAddEnvelopeTagFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addEnvelopeTagRequest.toString()),
});

const handleRemoveEnvelopeTagRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, removeEnvelopeTagRequest.toString()],
});

const handleRemoveEnvelopeTagSuccess: EnvelopesReducer<CommunicationEnvelope> = (
  state,
  { payload }
) => {
  const item = state.envelopes[payload.uuid] || {};
  const updatedEnvelope = {
    [payload.uuid]: { ...item, tags: payload.tags, action_summary: payload.action_summary },
  };

  const itemSummary = state.summaries[payload.uuid] || {};
  const updatedSummary = {
    [payload.uuid]: { ...itemSummary, envelope: { ...itemSummary.envelope, tags: payload.tags } },
  };

  return {
    ...state,
    envelopes: { ...state.envelopes, ...updatedEnvelope },
    summaries: { ...state.summaries, ...updatedSummary },
  };
};

const handleRemoveEnvelopeTagFulfill: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== removeEnvelopeTagRequest.toString()),
});

const handleCleanEnvelopesState: EnvelopesReducer<CommunicationEnvelope> = (state) => ({
  ...state,
  summaries: {},
});

const handleRemoveEnvelopeTagFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== removeEnvelopeTagRequest.toString()),
});

const handleMarkEnvelopeAsRead: EnvelopesReducer<MarkEnvelopeAsReadPayload> = (state, action) => {
  const { envelopeId } = action.payload;

  if (state.summaries[envelopeId] !== undefined) {
    return {
      ...state,
      summaries: {
        ...state.summaries,
        [envelopeId]: {
          ...state.summaries[envelopeId],
          envelope: {
            ...state.summaries[envelopeId].envelope,
            is_read: true,
          },
        },
      },
    };
  }
  return {
    ...state,
    envelopes: {
      ...state.envelopes,
      [envelopeId]: {
        ...state.envelopes[envelopeId],
        is_read: true,
      },
    },
  };
};

const handleMarkEnvelopeAsReadFailure: EnvelopesReducer<ErrorObject> = (state, action) => ({
  ...state,
  error: action.payload,
});

const handleMarkEnvelopeAsReadSuccess: EnvelopesReducer<CommunicationEnvelope> = (
  state,
  action
) => {
  const envelopeId = action.payload.uuid;

  if (state.summaries[envelopeId] !== undefined) {
    return {
      ...state,
      summaries: {
        ...state.summaries,
        [envelopeId]: {
          ...state.summaries[envelopeId],
          envelope: {
            ...state.summaries[envelopeId].envelope,
            ...action.payload,
          },
        },
      },
    };
  }

  return {
    ...state,
    envelopes: {
      ...state.envelopes,
      [envelopeId]: {
        ...state.envelopes[envelopeId],
        ...action.payload,
      },
    },
  };
};

const handleAddBulkEnvelopeTagsRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addBulkEnvelopeTagsRequest.toString()],
});

const handleAddBulkEnvelopeTagsFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addBulkEnvelopeTagsRequest.toString()),
});

const handleAddBulkEnvelopeTagsSuccess: EnvelopesReducer<CommunicationEnvelope[]> = (
  state,
  { payload }
) => {
  const updatedEnvelopes: NormalizedResource<CommunicationEnvelopeSummary> = {};
  payload.forEach((envelope) => {
    const actualEnvelope = { ...state.summaries[envelope.uuid] };
    actualEnvelope.envelope = envelope;
    updatedEnvelopes[envelope.uuid] = actualEnvelope;
  });

  return {
    ...state,
    summaries: {
      ...state.summaries,
      ...updatedEnvelopes,
    },
    loading: state.loading.filter((s) => s !== addBulkEnvelopeTagsRequest.toString()),
  };
};

const handleBulkReviewEnvelopesSuccess: EnvelopesReducer<CommunicationEnvelope[]> = (
  state,
  { payload }
) => {
  const updatedEnvelopes: NormalizedResource<CommunicationEnvelopeSummary> = {};
  payload.forEach((envelope) => {
    const actualEnvelope = { ...state.summaries[envelope.uuid] };
    actualEnvelope.envelope = envelope;
    updatedEnvelopes[envelope.uuid] = actualEnvelope;
  });

  return {
    ...state,
    summaries: {
      ...state.summaries,
      ...updatedEnvelopes,
    },
    loading: state.loading.filter((s) => s !== bulkReviewEnvelopesRequest.toString()),
  };
};

const handleAddBulkEnvelopeTagsSuccessAsync: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: state.loading.filter((s) => s !== addBulkEnvelopeTagsRequest.toString()),
});

const handleAddBulkAllEnvelopeTagsRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addBulkAllEnvelopeTagsRequest.toString()],
});

const handleAddBulkAllEnvelopeTagsFailure: EnvelopesReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addBulkAllEnvelopeTagsRequest.toString()),
});

const handleAddBulkAllEnvelopeTagsSuccess: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: state.loading.filter((s) => s !== addBulkAllEnvelopeTagsRequest.toString()),
});

const handleFetchEnvelopeEsDocsRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchEnvelopeEsDocsRequest.toString()],
});

const handleFetchEnvelopeEsDocsFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchEnvelopeEsDocsRequest.toString()),
});

const handleFetchEnvelopeEsDocsSuccess: EnvelopesReducer<CommunicationEnvelopeEsDocs> = (
  state,
  { payload }
) => ({
  ...state,
  esDocs: payload,
});

const handleFetchEnvelopeEsDocsAsIndexedRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchEnvelopeEsDocsAsIndexedRequest.toString()],
});

const handleFetchEnvelopeEsDocsAsIndexedFailure: EnvelopesReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== fetchEnvelopeEsDocsAsIndexedRequest.toString()),
});

const handleFetchEnvelopeEsDocsAsIndexedSuccess: EnvelopesReducer<
  CommunicationEnvelopeEsDocsAsIndexed
> = (state, { payload }) => ({
  ...state,
  esDocsAsIndexed: payload,
});

const handleExportEnvelopesSearchRequest: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== exportEnvelopesSearch.toString()),
});

const handleExportEnvelopesSearchSuccess: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== exportEnvelopesSearch.toString()),
});

const handleExportEnvelopesSearchFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== exportEnvelopesSearchFailure.toString()),
});

const handleExportNgramRequest: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== exportNgramRequest.toString()),
});

const handleExportNgramSuccess: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== exportNgramRequest.toString()),
});

const handleExportNgramFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== exportNgramRequest.toString()),
});

const handleReprocessBulkRequest: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== reprocessBulkRequest.toString()),
});

const handleReprocessBulkSuccess: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== reprocessBulkRequest.toString()),
});

const handleReprocessBulkFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== reprocessBulkRequest.toString()),
});

const handleExtractSenderDomainsRequest: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== extractSenderDomainsRequest.toString()),
});

const handleExtractSenderDomainsSuccess: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== extractSenderDomainsRequest.toString()),
});

const handleExtractSenderDomainsFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== extractSenderDomainsRequest.toString()),
});

const handleExtractRecipientDomainsRequest: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== extractSenderDomainsRequest.toString()),
});

const handleExtractRecipientDomainsSuccess: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== extractSenderDomainsRequest.toString()),
});

const handleExtractRecipientDomainsFailure: EnvelopesReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== extractSenderDomainsRequest.toString()),
});

const handleAddActionsBulkEnvelopesRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addActionsBulkEnvelopeRequest.toString()],
});

const handleAddActionsBulkEnvelopesSuccess: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== addActionsBulkEnvelopeRequest.toString()),
});

const handleAddActionsBulkEnvelopesFailure: EnvelopesReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addActionsBulkEnvelopeRequest.toString()),
});

const reviewEnvelopesRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, reviewEnvelopeRequest.toString()],
});

const reviewEnvelopesSuccess: EnvelopesReducer<CommunicationEnvelope> = (state, { payload }) => ({
  ...state,
  envelopes: { ...state.envelopes, [payload.uuid]: payload },
  summaries: {
    ...state.summaries,
    ...(state.summaries[payload.uuid]
      ? { [payload.uuid]: { ...state.summaries[payload.uuid], envelope: payload } }
      : {}),
  },
  loading: state.loading.filter((s) => s !== reviewEnvelopeRequest.toString()),
});

const reviewEnvelopesFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== reviewEnvelopeRequest.toString()),
});

const reprocessEnvelopesRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, reprocessEnvelopeRequest.toString()],
});

const reprocessEnvelopesSuccess: EnvelopesReducer<CommunicationEnvelope> = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== reprocessEnvelopeRequest.toString()),
});

const reprocessEnvelopesFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== reprocessEnvelopeRequest.toString()),
});

const handleAddCommentEnvelopeRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addCommentEnvelopeRequest.toString()],
});

const handleAddCommentEnvelopeFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addCommentEnvelopeRequest.toString()),
});

const handleAddCommentEnvelopeSuccess: EnvelopesReducer<CommunicationEnvelope> = (
  state,
  { payload }
) => ({
  ...state,
  envelopes: {
    ...state.envelopes,
    [payload.uuid]: payload,
  },
  loading: state.loading.filter((s) => s !== addCommentEnvelopeRequest.toString()),
});

const handleFetchCommentEnvelopeSuccess: EnvelopesReducer<EnvelopeAction> = (
  state,
  { payload }
) => {
  const actionArray: EnvelopeAction[] | undefined = state.envelopes[payload.envelope_uuid]?.actions;

  // Use a type guard to ensure that myArray is an array before using map
  if (Array.isArray(actionArray)) {
    const updatedActions = actionArray.map((action) => {
      if (action.uuid === payload.uuid) {
        return {
          ...action,
          ...payload,
        };
      }
      return action;
    });

    // Return a new state object with the updated actions array
    return {
      ...state,
      envelopes: {
        ...state.envelopes,
        [payload.envelope_uuid]: {
          ...state.envelopes[payload.envelope_uuid],
          actions: updatedActions,
        },
      },
    };
  }
  return state;
};

const handleUpdateCommentEnvelopeSuccess: EnvelopesReducer<EnvelopeAction> = (
  state,
  { payload }
) => {
  const actionArray: EnvelopeAction[] | undefined = state.envelopes[payload.envelope_uuid]?.actions;

  // Use a type guard to ensure that myArray is an array before using map
  if (Array.isArray(actionArray)) {
    const updatedActions = actionArray.map((action) => {
      if (action.uuid === payload.uuid) {
        return {
          ...action,
          ...payload,
        };
      }
      return action;
    });

    // Return a new state object with the updated actions array
    return {
      ...state,
      envelopes: {
        ...state.envelopes,
        [payload.envelope_uuid]: {
          ...state.envelopes[payload.envelope_uuid],
          actions: updatedActions,
        },
      },
    };
  }
  return state;
};

const handleDeleteCommentEnvelopeSuccess: EnvelopesReducer<deleteEnvelopeActionPayload> = (
  state,
  { payload }
) => {
  const actionArray: EnvelopeAction[] | undefined = state.envelopes[payload.envelopeId]?.actions;

  // Use a type guard to ensure that myArray is an array before using map
  if (Array.isArray(actionArray)) {
    const updatedActions = actionArray.filter((action) => action.deleted_at === null);

    // Return a new state object with the updated actions array
    return {
      ...state,
      envelopes: {
        ...state.envelopes,
        [payload.envelopeId]: {
          ...state.envelopes[payload.envelopeId],
          actions: updatedActions,
        },
      },
    };
  }
  return state;
};

const handleAddNotifyEnvelopeRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, addNotifyEnvelopeRequest.toString()],
});

const handleAddNotifyEnvelopeFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
  loading: state.loading.filter((s) => s !== addNotifyEnvelopeRequest.toString()),
});

const handleAddNotifyEnvelopeSuccess: EnvelopesReducer<CommunicationEnvelope> = (
  state,
  { payload }
) => ({
  ...state,
  envelopes: {
    ...state.envelopes,
    [payload.uuid]: payload,
  },
  loading: state.loading.filter((s) => s !== addNotifyEnvelopeRequest.toString()),
});

const handleFetchFlaggedTextRequest: EnvelopesReducer<{ envelopeIds: UUID[] }> = (
  state,
  { payload }
) => ({
  ...state,
  error: null,
  loading: [...state.loading, ...payload.envelopeIds],
});

const handleFetchFlaggedTextSuccess: EnvelopesReducer<FetchFlaggedTextSuccessPayload> = (
  state,
  { payload }
) => {
  if (payload.records.length === 0) return state;

  const summaries = { ...state.summaries };
  const flaggedTextListIds: UUID[] = [...state.flaggedTextlistIds];
  payload.records.forEach((flaggedText) => {
    if (!flaggedTextListIds.includes(flaggedText.envelope_uuid)) {
      flaggedTextListIds.push(flaggedText.envelope_uuid);
    }
    const summary = summaries[flaggedText.envelope_uuid];
    if (summary) {
      if (summary.events.length === 0) {
        summaries[flaggedText.envelope_uuid] = {
          ...summary,
          noMatchEvent: { sentences: flaggedText.sentences },
        };
      } else {
        summaries[flaggedText.envelope_uuid] = {
          ...summary,
          events: summary.events.map((event) =>
            event.uuid === flaggedText.event_uuid || (event.annotations?.length || 0) === 0
              ? {
                  ...event,
                  sentences: flaggedText.sentences || undefined,
                  annotations: flaggedText.annotations || null,
                }
              : event
          ),
        };
      }
    }
  });

  return {
    ...state,
    summaries,
    flaggedTextlistIds: flaggedTextListIds,
  };
};

const handleFetchFlaggedTextFailure: EnvelopesReducer<ErrorObject> = (state, { payload }) => ({
  ...state,
  error: payload,
});

const handleFetchFlaggedTextFulfill: EnvelopesReducer<{ envelopeIds: UUID[] }> = (
  state,
  { payload }
) => ({
  ...state,
  loading: state.loading.filter((s) => !payload.envelopeIds.includes(s)),
});

const handleReviewAndContinueNextEnvelopeRequest: EnvelopesReducer = (state) => ({
  ...state,
  loading: [...state.loading, reviewAndContinueNextEnvelopeRequest.toString()],
});

const handleReviewAndContinueNextEnvelopeFullfil: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== reviewAndContinueNextEnvelopeRequest.toString()),
});

const handleFetchEnvelopeAttachmentsRequest: EnvelopesReducer = (state) => ({
  ...state,
  error: null,
  loading: [...state.loading, fetchEnvelopeAttachmentsRequest.toString()],
});

const handleFetchEnvelopeAttachmentsSuccess: EnvelopesReducer<{
  attachments: Attachment[];
  envelopeUuid: UUID;
}> = (state, { payload }) => {
  const { attachments, envelopeUuid } = payload;

  const envelope = state.envelopes[envelopeUuid];
  if (!envelope) return state;

  const newEnvelope = { ...envelope, attachments };

  return {
    ...state,
    envelopes: { ...state.envelopes, [envelopeUuid]: newEnvelope },
  };
};

const handleFetchEnvelopeAttachmentsFailure: EnvelopesReducer<ErrorObject> = (
  state,
  { payload }
) => ({
  ...state,
  error: payload,
});

const handleFetchEnvelopeAttachmentsFulfill: EnvelopesReducer = (state) => ({
  ...state,
  loading: state.loading.filter((s) => s !== fetchEnvelopeAttachmentsRequest.toString()),
});

const handleFetchMoreLikeThisRequest: EnvelopesReducer = (state) => ({
  ...state,
  loading: [...state.loading, fetchMoreLikeThisRequest.toString()],
});

const handleFetchMoreLikeThisFulfill: EnvelopesReducer<MoreLikeThisPoint[]> = (
  state,
  { payload }
) => ({
  ...state,
  moreLikeThis: payload,
  loading: state.loading.filter((s) => s !== fetchMoreLikeThisRequest.toString()),
});

const handleAddSelectedFilter: EnvelopesReducer<SelectedFilterPayload> = (
  state: EnvelopesState,
  { payload }
) => {
  const selectedFilters = [...state.selectedFilters];
  if (
    selectedFilters.some(
      (filter) => filter.id !== payload.node.id && payload.node.parent !== filter.parent
    )
  ) {
    return {
      ...state,
      selectedFilters: [payload.node],
    };
  }
  return {
    ...state,
    selectedFilters: [...state.selectedFilters, payload.node],
  };
};

const handleRemoveSelectedFilter: EnvelopesReducer<SelectedFilterPayload> = (
  state: EnvelopesState,
  { payload }
) => {
  const selectedFilters = [...state.selectedFilters];
  const index = selectedFilters.findIndex((filter) => filter.id === payload.node.id);
  selectedFilters.splice(index, 1);
  return {
    ...state,
    selectedFilters,
  };
};

const handleSetTree: EnvelopesReducer<{ tree: Tree }> = (state: EnvelopesState, { payload }) => ({
  ...state,
  filtersTree: payload.tree,
});

const handleClearAllSelectedFilters: EnvelopesReducer = (state: EnvelopesState) => ({
  ...state,
  selectedFilters: [],
});

const handleAddSelectedField: EnvelopesReducer<SelectedFieldPayload> = (
  state: EnvelopesState,
  { payload }
) => {
  const selectedFields = [...state.selectedFields];

  if (
    selectedFields.some(
      (field) => field.id !== payload.node.id && payload.node.parent !== field.parent
    )
  ) {
    return {
      ...state,
      selectedFields: [payload.node],
    };
  }

  return {
    ...state,
    selectedFields: [...state.selectedFields, payload.node],
  };
};

const handleRemoveSelectedField: EnvelopesReducer<SelectedFieldPayload> = (
  state: EnvelopesState,
  { payload }
) => {
  const selectedFields = [...state.selectedFields];
  const index = selectedFields.findIndex((field) => field.id === payload.node.id);
  selectedFields.splice(index, 1);
  return {
    ...state,
    selectedFields,
  };
};

const handleClearAllSelectedFields: EnvelopesReducer = (state: EnvelopesState) => ({
  ...state,
  selectedFields: [],
});

const handleChangeRequestTree: EnvelopesReducer<{ value: boolean }> = (
  state: EnvelopesState,
  { payload }
) => ({
  ...state,
  requestTreeFilters: payload.value,
});

const handleClearTree: EnvelopesReducer = (state: EnvelopesState) => ({
  ...state,
  filtersTree: {
    op: Operator.AND,
    data: [],
    id: v4(),
  },
});

const handlers = {
  [fetchMoreLikeThisRequest.toString()]: handleFetchMoreLikeThisRequest,
  [fetchMoreLikeThisFulfill.toString()]: handleFetchMoreLikeThisFulfill,
  [fetchAllEnvelopes.toString()]: handleFetchAllEnvelopesRequest,
  [fetchAllEnvelopesRequest.toString()]: handleFetchAllEnvelopesRequest,
  [fetchAllEnvelopesSuccess.toString()]: handleFetchAllEnvelopesSuccess,
  [fetchAggsRequest.toString()]: handleFetchAllEnvelopesRequest,
  [fetchAggsSuccess.toString()]: handleFetchAllEnvelopesSuccess,
  [fetchAllEnvelopesPrototypeSuccess.toString()]: handleFetchAllEnvelopesPrototypeSuccess,
  [fetchAllEnvelopesFailure.toString()]: handleFetchAllEnvelopesFailure,
  [fetchSingleEnvelopeRequest.toString()]: handleFetchSingleEnvelopeRequest,
  [fetchSingleEnvelopeSuccess.toString()]: handleFetchSingleEnvelopeSuccess,
  [fetchSingleEnvelopeFailure.toString()]: handleFetchSingleEnvelopeFailure,
  [fetchEnvelopeThreadRequest.toString()]: handleFetchEnvelopeThreadRequest,
  [fetchEnvelopeThreadSuccess.toString()]: handleFetchEnvelopeThreadSuccess,
  [fetchEnvelopeThreadFailure.toString()]: handleFetchEnvelopeThreadFailure,
  [starEnvelopeRequest.toString()]: handleStarEnvelopeRequest,
  [starEnvelopeFailure.toString()]: handleStarEnvelopeFailure,
  [starEnvelopeSuccess.toString()]: handleStarEnvelopeSuccess,
  [addEnvelopeTagRequest.toString()]: handleAddEnvelopeTagRequest,
  [addEnvelopeTagFailure.toString()]: handleAddEnvelopeTagFailure,
  [addEnvelopeTagSuccess.toString()]: handleAddEnvelopeTagSuccess,
  [addEnvelopeTagFulfill.toString()]: handleAddEnvelopeTagFulfill,
  [removeEnvelopeTagRequest.toString()]: handleRemoveEnvelopeTagRequest,
  [removeEnvelopeTagFailure.toString()]: handleRemoveEnvelopeTagFailure,
  [removeEnvelopeTagSuccess.toString()]: handleRemoveEnvelopeTagSuccess,
  [removeEnvelopeTagFulfill.toString()]: handleRemoveEnvelopeTagFulfill,
  [cleanEnvelopesState.toString()]: handleCleanEnvelopesState,
  [markEnvelopeAsRead.toString()]: handleMarkEnvelopeAsRead,
  [markEnvelopeAsReadFailure.toString()]: handleMarkEnvelopeAsReadFailure,
  [markEnvelopeAsReadSuccess.toString()]: handleMarkEnvelopeAsReadSuccess,
  [addBulkEnvelopeTagsRequest.toString()]: handleAddBulkEnvelopeTagsRequest,
  [addBulkEnvelopeTagsFailure.toString()]: handleAddBulkEnvelopeTagsFailure,
  [addBulkEnvelopeTagsSuccess.toString()]: handleAddBulkEnvelopeTagsSuccess,
  [removeBulkEnvelopeTagsSuccess.toString()]: handleAddBulkEnvelopeTagsSuccess,
  [addBulkAllEnvelopeTagsRequest.toString()]: handleAddBulkAllEnvelopeTagsRequest,
  [addBulkAllEnvelopeTagsSuccess.toString()]: handleAddBulkAllEnvelopeTagsSuccess,
  [addBulkAllEnvelopeTagsFailure.toString()]: handleAddBulkAllEnvelopeTagsFailure,
  [fetchEnvelopeEsDocsRequest.toString()]: handleFetchEnvelopeEsDocsRequest,
  [fetchEnvelopeEsDocsFailure.toString()]: handleFetchEnvelopeEsDocsFailure,
  [fetchEnvelopeEsDocsSuccess.toString()]: handleFetchEnvelopeEsDocsSuccess,
  [fetchEnvelopeEsDocsAsIndexedRequest.toString()]: handleFetchEnvelopeEsDocsAsIndexedRequest,
  [fetchEnvelopeEsDocsAsIndexedFailure.toString()]: handleFetchEnvelopeEsDocsAsIndexedFailure,
  [fetchEnvelopeEsDocsAsIndexedSuccess.toString()]: handleFetchEnvelopeEsDocsAsIndexedSuccess,
  [exportEnvelopesSearchRequest.toString()]: handleExportEnvelopesSearchRequest,
  [exportEnvelopesSearchSuccess.toString()]: handleExportEnvelopesSearchSuccess,
  [exportEnvelopesSearchFailure.toString()]: handleExportEnvelopesSearchFailure,
  [exportNgramRequest.toString()]: handleExportNgramRequest,
  [exportNgramSuccess.toString()]: handleExportNgramSuccess,
  [exportNgramFailure.toString()]: handleExportNgramFailure,
  [extractSenderDomainsRequest.toString()]: handleExtractSenderDomainsRequest,
  [extractSenderDomainsSuccess.toString()]: handleExtractSenderDomainsSuccess,
  [extractSenderDomainsFailure.toString()]: handleExtractSenderDomainsFailure,
  [extractRecipientDomainsRequest.toString()]: handleExtractRecipientDomainsRequest,
  [extractRecipientDomainsSuccess.toString()]: handleExtractRecipientDomainsSuccess,
  [extractRecipientDomainsFailure.toString()]: handleExtractRecipientDomainsFailure,
  [reprocessBulkRequest.toString()]: handleReprocessBulkRequest,
  [reprocessBulkSuccess.toString()]: handleReprocessBulkSuccess,
  [reprocessBulkFailure.toString()]: handleReprocessBulkFailure,
  [addActionsBulkEnvelopeRequest.toString()]: handleAddActionsBulkEnvelopesRequest,
  [addActionsBulkEnvelopeSuccess.toString()]: handleAddActionsBulkEnvelopesSuccess,
  [addActionsBulkEnvelopeFailure.toString()]: handleAddActionsBulkEnvelopesFailure,
  [reviewEnvelopeRequest.toString()]: reviewEnvelopesRequest,
  [reviewEnvelopeSuccess.toString()]: reviewEnvelopesSuccess,
  [reviewEnvelopeFailure.toString()]: reviewEnvelopesFailure,
  [reprocessEnvelopeRequest.toString()]: reprocessEnvelopesRequest,
  [reprocessEnvelopeSuccess.toString()]: reprocessEnvelopesSuccess,
  [reprocessEnvelopeFailure.toString()]: reprocessEnvelopesFailure,
  [addCommentEnvelopeRequest.toString()]: handleAddCommentEnvelopeRequest,
  [addCommentEnvelopeFailure.toString()]: handleAddCommentEnvelopeFailure,
  [addCommentEnvelopeSuccess.toString()]: handleAddCommentEnvelopeSuccess,
  [addNotifyEnvelopeRequest.toString()]: handleAddNotifyEnvelopeRequest,
  [addNotifyEnvelopeFailure.toString()]: handleAddNotifyEnvelopeFailure,
  [addNotifyEnvelopeSuccess.toString()]: handleAddNotifyEnvelopeSuccess,
  [fetchFlaggedTextRequest.toString()]: handleFetchFlaggedTextRequest,
  [fetchFlaggedTextFailure.toString()]: handleFetchFlaggedTextFailure,
  [fetchFlaggedTextSuccess.toString()]: handleFetchFlaggedTextSuccess,
  [fetchFlaggedTextFulfill.toString()]: handleFetchFlaggedTextFulfill,
  [addBulkEnvelopeTagsSuccessAsync.toString()]: handleAddBulkEnvelopeTagsSuccessAsync,
  [reviewAndContinueNextEnvelopeRequest.toString()]: handleReviewAndContinueNextEnvelopeRequest,
  [reviewAndContinueNextEnvelopeFulfill.toString()]: handleReviewAndContinueNextEnvelopeFullfil,
  [fetchEnvelopeAttachmentsRequest.toString()]: handleFetchEnvelopeAttachmentsRequest,
  [fetchEnvelopeAttachmentsSuccess.toString()]: handleFetchEnvelopeAttachmentsSuccess,
  [fetchEnvelopeAttachmentsFailure.toString()]: handleFetchEnvelopeAttachmentsFailure,
  [fetchEnvelopeAttachmentsFulfill.toString()]: handleFetchEnvelopeAttachmentsFulfill,
  [updateEnvelopeActionSuccess.toString()]: handleUpdateCommentEnvelopeSuccess,
  [deleteEnvelopeActionSuccess.toString()]: handleDeleteCommentEnvelopeSuccess,
  [fetchEnvelopeActionSuccess.toString()]: handleFetchCommentEnvelopeSuccess,
  [bulkReviewEnvelopesSuccess.toString()]: handleBulkReviewEnvelopesSuccess,
  [addSelectedFilter.toString()]: handleAddSelectedFilter,
  [removeSelectedFilter.toString()]: handleRemoveSelectedFilter,
  [clearSelectedFilters.toString()]: handleClearAllSelectedFilters,
  [addSelectedField.toString()]: handleAddSelectedField,
  [removeSelectedField.toString()]: handleRemoveSelectedField,
  [clearSelectedFields.toString()]: handleClearAllSelectedFields,
  [setTree.toString()]: handleSetTree,
  [requestTreeFiltersToogle.toString()]: handleChangeRequestTree,
  [clearTree.toString()]: handleClearTree,
};

const campaignsReducer = createReducer(defaultState, handlers);

export default campaignsReducer;
