import {
  fetchAllEventDocComm,
  fetchCommunicationDocument,
  fetchCommunicationSummary,
  selectSection,
} from 'actions';
import BackToTopButton from 'components/Button/BackToTopButton';
import ModalAddTestSuiteFromComm from 'components/ModalAddTestSuiteFromComm';
import Permissions from 'components/Permissions';
import Redacted from 'components/Redacted';
import { NEW_CHEVRON_LEFT } from 'constants/filterIcons';
import React, { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { getCustomerO365Config } from 'selectors/auth';
import {
  getCommunicationDocumentLoading,
  getCommunications,
  getSingleCommunication,
} from 'selectors/communications';
import {
  getCommunicationsWithEvents,
  getRuleFlips,
  getSelectedSection,
} from 'selectors/envelopeReview';
import { getShowMoreLikeThis } from 'selectors/envelopeView';
import { useSelector } from 'store';
import { CommunicationWithEvents } from 'types';
import logEvent from 'utils/analytics';
import parseBody from 'utils/strings';
import CommunicationContentHeader from './CommunicationContentHeader';
import CommunicationHistoryContent from './CommunicationHistoryContent';
import EnvelopeMessageInfo from './EnvelopeMessageInfo';
import EnvelopeSkeleton from './EnvelopeSkeleton';
import HighlightedSentenceComponent from './HighlightedSentence';
import MoreLikeThis from './MoreLikeThis';
import RenderThread from './RenderThread';

type ComponentProps = {
  communication: CommunicationWithEvents;
};

const NoMatchCommunicationContent: React.FC<ComponentProps> = ({ communication }) => {
  const dispatch = useDispatch();
  const communicationSummary = useSelector((state) =>
    getSingleCommunication(state, communication.uuid)
  );
  const existingComm = useSelector(getCommunications).find((c) => communication.uuid === c.uuid);
  const o365Config = useSelector(getCustomerO365Config);
  const showMoreLikeThis = useSelector(getShowMoreLikeThis);
  const teamsContextDepth = o365Config.teams_thread_context_depth || 5;

  const communicationLoading = useSelector(getCommunicationDocumentLoading);
  const selectedSection = useSelector(getSelectedSection);
  const ruleFlips = useSelector((state) => getRuleFlips(state, communication?.uuid || ''));
  const communications = useSelector(getCommunicationsWithEvents);

  const [showButton, setShowButton] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [raw, setRaw] = useState(false);

  const contentRef = useRef<HTMLDivElement>(null);

  const isTeamsComm = communication?.platform === 'o365_teams';

  useEffect(() => {
    if (!communicationSummary) {
      dispatch(fetchCommunicationSummary({ commId: communication.uuid }));
    }
  }, [dispatch, communication.uuid, communicationSummary]);

  useEffect(() => {
    if (communications) {
      dispatch(
        fetchAllEventDocComm({
          communications: [...communications, communication],
        })
      );
    }
  }, [dispatch, communications, communication]);

  useEffect(() => {
    if (communication && !existingComm) {
      dispatch(fetchCommunicationDocument({ communication }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communication.uuid, dispatch, existingComm]);

  useEffect(() => {
    if ((contentRef.current?.clientHeight || 0) < (contentRef.current?.scrollHeight || 0)) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  }, [communicationSummary]);

  const toggleModal = (): void => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleHistory = (): void => {
    if (selectedSection === 'history') {
      dispatch(selectSection('message'));
    } else {
      dispatch(selectSection('history'));
    }
  };

  if (communicationLoading) return <EnvelopeSkeleton />;

  return (
    <div className="overflow-y-hidden w-full h-full flex flex-col justify-between">
      <div className="relative w-full flex flex-col gap-4 overflow-hidden">
        <BackToTopButton scrollRef={contentRef} showButton={showButton} rightOffset={30} />

        <div ref={contentRef} className="relative flex flex-col gap-4 py-4 overflow-auto px-6">
          <CommunicationContentHeader communication={communication} />

          <EnvelopeMessageInfo />
          {isTeamsComm && communication?.platform_guid ? (
            <Redacted field="communications.thread_context">
              <div>
                {raw ? (
                  <RenderThread
                    eventId=""
                    commUuid={communication.uuid}
                    commPlatformUuid={communication?.platform_guid}
                    contextPadding={teamsContextDepth}
                    authorOnly={false}
                  />
                ) : (
                  <RenderThread
                    eventId=""
                    commUuid={communication.uuid}
                    commPlatformUuid={communication?.platform_guid}
                    contextPadding={teamsContextDepth - Math.floor(teamsContextDepth / 2)}
                    authorOnly
                  />
                )}

                <button
                  type="button"
                  className="w-56 py-1.5 bg-litlingo-gray-1 rounded text-base font-bold leading-5 focus:outline-none"
                  onClick={(): void => {
                    setRaw(!raw);
                  }}
                >
                  {raw ? 'Hide Messages' : 'Show Messages'}
                </button>
              </div>
            </Redacted>
          ) : (
            <Redacted field="communications.body">
              <HighlightedSentenceComponent
                eventId={`no-event-${communication.uuid}`}
                commUuid={communication.uuid || ''}
                fullMessageOptions="HIGHLIGHTED"
              />
            </Redacted>
          )}
          {communications && communications?.length > 1 && (
            <div className="flex flex-col gap-4 pl-1">
              <div className="flex flex-row gap-2 text-body">
                <button type="button" onClick={toggleHistory} className="focus:outline-none">
                  <div
                    className={`transform ${
                      selectedSection === 'history' ? '-rotate-90' : 'rotate-180'
                    }`}
                  >
                    {NEW_CHEVRON_LEFT}
                  </div>
                </button>
                <span className="text-litlingo-gray-5 italic">
                  {`History: ${ruleFlips} high risk matches prevented during message composition`}
                </span>
              </div>
              <div
                className={`w-full h-full flex flex-col overflow-hidden ${
                  selectedSection === 'history' ? '' : 'hidden'
                }`}
              >
                <CommunicationHistoryContent />
              </div>
            </div>
          )}
          {!isTeamsComm && (
            <Redacted field="communications.body">
              <button
                type="button"
                className="w-56 py-1.5 bg-litlingo-gray-1 rounded text-base font-bold leading-5 focus:outline-none"
                onClick={(): void => {
                  logEvent('envelope-full-thread-click');
                  setRaw(!raw);
                }}
              >
                {raw ? 'Hide Messages' : 'Show Messages'}
              </button>
              {raw && (
                <ReactMarkdown renderers={{ link: () => <></> }}>
                  {parseBody(
                    communicationSummary.body.substring(0, communicationSummary.body.length)
                  )}
                </ReactMarkdown>
              )}
            </Redacted>
          )}
        </div>
      </div>
      {isModalOpen && (
        <ModalAddTestSuiteFromComm communication={communicationSummary} toggleModal={toggleModal} />
      )}
      {showMoreLikeThis && communication.envelope_uuid && (
        <Permissions action="communication_envelopes.more_like_this">
          <div className="relative flex flex-col">
            <MoreLikeThis envelopeUuid={communication.envelope_uuid} />
          </div>
        </Permissions>
      )}
    </div>
  );
};

export default NoMatchCommunicationContent;
